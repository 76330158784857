import React, { useEffect, useMemo, useRef, useState } from 'react';
import { ArrowRepeat, Floppy } from 'react-bootstrap-icons';
import Button from 'react-bootstrap/Button';
import Setting from '../../services/setting';
import Content from '../Content';
import SearchBar from '../SearchBar';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import AlertBox from '../AlertBox';

import { AgGridReact } from 'ag-grid-react'; // AG Grid Component
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the grid
import "ag-grid-community/styles/ag-theme-quartz.css"; // Optional Theme applied to the grid
import LoadingPage from '../LoadingPage';
import FormButton from '../FormButton';
import Utils from '../../utils';

export default () => {
    const mounted = useRef(true);
    const [reload, setReload] = useState(true);
    const [settings, setSettings] = useState([]);
    const [isDirty, setIsDirty] = useState(false);
    const [alert, setAlert] = useState(null);

    const [submitting, setSubmitting] = useState(false);

    const [loading, setLoading] = useState(true);
    const [settingsLoading, setSettingsLoading] = useState(true);

    useEffect(() => { document.title = "Settings"; }, []);

    useEffect(() => {
        setLoading(settingsLoading);
    }, [settingsLoading]);

    useEffect(() => {
        setSubmitting(false);
    }, [alert]);

    const [colDefs, setColDefs] = useState([
        { field: 'key', flex: 1 },
        { field: 'value', flex: 5, editable: true },
    ]);
    const defaultColDef = useMemo(() => {
        return {
            flex: 1,
            comparator:  Utils.defaultComparator,
        };
    }, []);

    useEffect(() => {
        mounted.current = true;
        if (!reload) {
            return;
        }

        Setting.get()
            .then(items => {
                if (mounted.current) {
                    setSettings(items);
                    setReload(false);
                    setSettingsLoading(false);
                }
            });
        return () => mounted.current = false;
    }, [reload]);

    useEffect(() => {
        if (isDirty) {
            setAlert(null);
            window.removeEventListener("beforeunload", beforeUnloadHandler);
            window.addEventListener("beforeunload", beforeUnloadHandler);
        }
        return () => {
            window.removeEventListener("beforeunload", beforeUnloadHandler);
        }
    }, [isDirty]);

    const beforeUnloadHandler = (event) => {
        event.preventDefault();
        event.returnValue = true;
    };

    const onCellValueChanged = (params) => {
        //var changedData = [params.data];
        setIsDirty(true);
        //params.api.applyTransaction({ update: changedData });
    }

    const onSave = () => {
        setSubmitting(true);
        Setting.addUpdate(settings)
            .then(item => {
                if (item.error) {
                    return setAlert({ message: item.error, isError: true });
                }

                setIsDirty(false);
                setReload(true);
                setAlert({ message: `Saved`, isError: false });
            });
    }

    return (
        <LoadingPage loading={loading} submitting={submitting}>
            <SearchBar>
                <Row style={{ display: 'flex', alignItems: 'center' }}>
                    <Col sm style={{ display: 'flex', alignItems: 'center', fontStyle: 'bold' }}>Settings</Col>
                    <Col sm="auto"><Button variant="outline-success" onClick={() => onSave()} title='Save' disabled={submitting}><Floppy size={30} /></Button></Col>
                    <Col sm="auto"><FormButton variant="outline-secondary" onClick={() => setReload(true)} title='Refresh' loading={reload} disabled={submitting}><ArrowRepeat size={30} /></FormButton></Col>
                </Row>
            </SearchBar>
            <Content className="ag-theme-quartz p-3">
                <AgGridReact
                    defaultColDef={defaultColDef}
                    columnDefs={colDefs}
                    domLayout="autoHeight"
                    suppressScrollOnNewData='true'
                    onCellValueChanged={onCellValueChanged}
                    rowData={settings}
                />
            </Content>

            {alert &&
                <AlertBox isError={alert.isError} onClose={() => setAlert(null)}>
                    {alert.message}
                </AlertBox>}
        </LoadingPage>
    );
}