class Project {
  async get(filter) {
    const data = await fetch(`${process.env.REACT_APP_API_URL}/projects?q=${filter}`, {
      headers: {
        'x-access-token': localStorage.getItem('token')
      }
    });
    return await data.json();
  }

  async getById(id) {
    if (id === '0') {
      return {};
    }
    const data = await fetch(`${process.env.REACT_APP_API_URL}/projects/${id}`, {
      headers: {
        'x-access-token': localStorage.getItem('token')
      }
    });
    return await data.json();
  }

  async addUpdate(record) {
    const data = await fetch(`${process.env.REACT_APP_API_URL}/projects/${record.id || ''}`, {
      method: record.id ? 'PUT' : 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': localStorage.getItem('token')
      },
      body: JSON.stringify(record)
    });
    return await data.json();
  }

  async delete(record) {
    const data = await fetch(`${process.env.REACT_APP_API_URL}/projects/${record.id}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': localStorage.getItem('token')
      }
    });
    return await data.json();
  }

  async getImage(id) {
    const res = await fetch(`${process.env.REACT_APP_API_URL}/files/${id}/projectImage.png`, {
      headers: {
        'x-access-token': localStorage.getItem('token')
      }
    });
    const imageBlob = await res.blob();
    const imageObjectURL = URL.createObjectURL(imageBlob);
    return imageObjectURL;
  }

  async setImage(id, file) {
    const formData = new FormData();
    formData.append('file', file);

    const data = await fetch(`${process.env.REACT_APP_API_URL}/files/${id}/projectImage`, {
      method: 'POST',
      headers: {
        'x-access-token': localStorage.getItem('token')
      },
      body: formData
    });
    return await data.json();
  }
}

module.exports = new Project();