import React, { useEffect, useMemo, useRef, useState } from 'react';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Badge from 'react-bootstrap/Badge';
import InputGroup from 'react-bootstrap/InputGroup';
import Template from '../../services/template';
import { ArrowRepeat, PlusLg } from 'react-bootstrap-icons';
import SearchBar from '../SearchBar';
import Content from '../Content';
import AlertBox from '../AlertBox';
import FormButton from '../FormButton';
import LoadingPage from '../LoadingPage';
import CellDocumentLinkRenderer from '../CellDocumentLinkRenderer';
import CellDelete from '../CellDelete';
import CellStatus from '../CellStatus';

import { AgGridReact } from 'ag-grid-react'; // AG Grid Component
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the grid
import "ag-grid-community/styles/ag-theme-quartz.css"; // Optional Theme applied to the grid
import Utils from '../../utils';

export default function PageTemplate() {
    const mounted = useRef(true);
    const [reload, setReload] = useState(true);
    const [alert, setAlert] = useState(null);
    const [list, setList] = useState([]);
    const [deleteFileName, setDeleteFileName] = useState('');
    const [searchText, setSearchText] = useState('');
    const [uploadResponseAlert, setUploadResponseAlert] = useState([]);

    const [submitting, setSubmitting] = useState(false);

    const [loading, setLoading] = useState(true);
    const [listLoading, setListLoading] = useState(true);

    const [colDefs, setColDefs] = useState([
        {
            field: 'name',
            headerName: 'Documents',
            cellRenderer: CellDocumentLinkRenderer,
            cellRendererParams: (params) => { return { onClick: () => Template.download(params.data.name).then(() => {}), title: params.data.name } }
        },
        {
            headerName: 'Type',
            maxWidth: 70,
            valueGetter: p => p.data.name?.split('.').slice(-1).pop()
        },
        {
            field: 'delete',
            headerName: '',
            maxWidth: 55,
            cellRenderer: CellDelete,
            cellRendererParams: (params) => { return { onClick: () => setDeleteFileName(params.data.name) } },
            sortable: false
        }
    ]);
    const [colDefsUploadedFiles, setColDefsUploadedFiles] = useState([
        {
            headerName: '',
            maxWidth: 55,
            cellRenderer: CellStatus,
            cellRendererParams: (params) => {return {error: params.data.error }},
            valueGetter: p => p.data.error ? '1' : '2'
        },
        {
            field: 'name',
            flex: 2
        },
        {
            field: 'error',
            flex: 1,
            valueGetter: p => p.data.error ?? 'Success'
        }
    ]);
    const defaultColDef = useMemo(() => {
        return {
            flex: 1,
            comparator:  Utils.defaultComparator,
        };
    }, []);

    useEffect(() => {
        setLoading(listLoading)
    }, [listLoading]);

    useEffect(() => {
        setSubmitting(false);
    }, [alert, uploadResponseAlert]);

    useEffect(() => { document.title = "Templates"; }, []);
    useEffect(() => {
        window.addEventListener("focus", () => setReload(true))
        return () => { window.removeEventListener("focus", () => setReload(true)); }
    }, []);
    
    useEffect(() => {
        mounted.current = true;
        if (list.length && !reload) {
            return;
        }

        Template.get(searchText)
            .then(items => {
                if (mounted.current) {
                    setList(items)
                    setReload(false);
                    setListLoading(false);
                }
            })
        return () => mounted.current = false;
    }, [reload]);

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            setReload(true);
          }, 1000);
      
          return () => clearTimeout(delayDebounceFn);
    }, [searchText]);

    useEffect(() => {
        if (submitting) {
            setDeleteFileName('');
            return;
        }

        if (deleteFileName === '') {
            return;
        }

        if (window.confirm("Are you sure you want to delete?") === true) {
            setSubmitting(true);
            Template.delete(deleteFileName)
                .then(() => {setReload(true); setSubmitting(false);});
        }

        setDeleteFileName('');

    }, [deleteFileName])

    const uploadFiles = async (e) => {
        setSubmitting(true);
        setUploadResponseAlert(await Template.upload(e.target.files));
        e.target.value = '';
        setReload(true);
    }

    return (
        <LoadingPage loading={loading} submitting={submitting}>
            <SearchBar>
                <Row style={{ display: 'flex', alignItems: 'center', height: '2.75rem' }}>
                    <Col sm="auto"><Badge bg="danger" style={{fontSize:'1.2rem'}}>{list.length}</Badge></Col>
                    <Col sm><Form.Control type="search" placeholder="Search" aria-label="Search project" aria-describedby="basic-addon2" value={searchText} onChange={(e) => setSearchText(e.target.value)} /></Col>
                    <Col sm="auto"><FormButton variant="outline-secondary" onClick={() => setReload(true)} title='Refresh' loading={reload}><ArrowRepeat size={30} /></FormButton></Col>
                </Row>
            </SearchBar>
            <Content>
                <Row xs={3} md={6}  className="justify-content-md-center g-4">
                    <Col lg={4}>
                    <InputGroup className="mb-3">
                        <Form.Control type="file" name="file" accept='.doc,.docx' multiple onChange={(e) => uploadFiles(e)} disabled={submitting} />
                        {/* <Button type="submit" value="Upload" onClick={uploadFiles}>Upload</Button> */}
                    </InputGroup>

                    <div className="ag-theme-quartz">
                        <AgGridReact
                            defaultColDef={defaultColDef}
                            columnDefs={colDefs}
                            domLayout="autoHeight"
                            suppressScrollOnNewData='true'
                            rowData={list}
                        />
                    </div>
                    </Col>
                    {/* <Col lg>
                        <iframe src="https://view.officeapps.live.com/op/embed.aspx?src=https://go.microsoft.com/fwlink/?LinkID=521962" height="600rem" width="100%" />
                    </Col> */}
                </Row>
            </Content>

            {alert &&
                <AlertBox isError={alert.isError} onClose={() => setAlert(null)}>
                    {alert.message}
                </AlertBox>}

                {uploadResponseAlert && uploadResponseAlert.length > 0 &&
                <AlertBox isError={uploadResponseAlert.findIndex(r => r.error) !== -1} onClose={() => setUploadResponseAlert([])}>
                    {uploadResponseAlert.length === 1 &&
                        <div>{uploadResponseAlert[0].error ? uploadResponseAlert[0].error : 'File uploaded successfully'}</div>
                    }
                    {uploadResponseAlert.length > 1 &&
                        <div className="ag-theme-quartz" style={{ height: '20rem', width: '28rem' }}>
                            <AgGridReact
                                defaultColDef={defaultColDef}
                                columnDefs={colDefsUploadedFiles}
                                // domLayout="autoHeight"
                                suppressScrollOnNewData='true'
                                rowData={uploadResponseAlert}
                            />
                        </div>}
                </AlertBox>}
        </LoadingPage>
    );
}