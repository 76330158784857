import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useParams } from 'react-router';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Project from '../../services/project';
import Customer from '../../services/customer';
import ProjectUnit from '../../services/projectUnit';
import { ArrowsMove, CashStack, CurrencyRupee, Floppy, Newspaper, PlusLg, Trash } from 'react-bootstrap-icons';
import FormInput from '../../components/FormInput';
import FormSelect from '../../components/FormSelect';
import SearchBar from '../SearchBar';
import Content from '../Content';
import AlertBox from '../AlertBox';
import LoadingPage from '../LoadingPage';

import CommentList from '../CommentList';
import FileList from '../FileList';
import FormButton from '../FormButton';
import CellDocumentLinkRenderer from '../CellDocumentLinkRenderer';

import { AgGridReact } from 'ag-grid-react'; // AG Grid Component
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the grid
import "ag-grid-community/styles/ag-theme-quartz.css"; // Optional Theme applied to the grid
import Utils from '../../utils';

export default function PageProjectUnitEdit() {
    const mounted = useRef(true);
    const [projectId, setProjectId] = useState(useParams().projectId);
    const [disableDelete, setDisableDelete] = useState(false);
    const [id, setId] = useState(useParams().id);
    const [isDirty, setIsDirty] = useState(false);
    const [reload, setReload] = useState(true);
    const [alert, setAlert] = useState(null);
    const [projectUnit, setProjectUnit] = useState({});
    const [customers, setCustomers] = useState([]);
    const [showCustomerList, setShowCustomerList] = useState(false);
    const [projectUnitTypes, setProjectUnitTypes] = useState([]);
    const [projectUnitStatuses, setProjectUnitStatuses] = useState([]);

    const [submitting, setSubmitting] = useState(false);

    const [loading, setLoading] = useState(true);
    const [projectUnitLoading, setProjectUnitLoading] = useState(true);

    const [colDefsCustomers, setColDefsCustomers] = useState([
        {
            headerName: 'Customers',
            field: 'name',
            cellRenderer: CellDocumentLinkRenderer,
            cellRendererParams: (params) => { return { onClick: () => onAddCustomer(params.data.id), title: params.data.name } }
        }
    ]);
    const defaultColDef = useMemo(() => {
        return {
            flex: 1,
            comparator:  Utils.defaultComparator,
        };
    }, []);

    useEffect(() => {
        setLoading(projectUnitLoading)
    }, [projectUnitLoading]);

    useEffect(() => {
        ProjectUnit.getTypes()
            .then(items => {
                setProjectUnitTypes(items);
            });
        ProjectUnit.getStatuses()
            .then(items => {
                setProjectUnitStatuses(items);
            });
    }, []);

    useEffect(() => {
        setSubmitting(false);
        setShowCustomerList(false);
    }, [alert]);

    useEffect(() => {
        if (showCustomerList) {
            Customer.get('')
                .then(items => {
                    setCustomers(items);
                });
        }
    }, [showCustomerList]);

    useEffect(() => { document.title = "Edit Project Unit"; }, []);

    useEffect(() => {
        mounted.current = true;
        if (!reload) {
            return;
        }

        if (id === '0') {
            Project.getById(projectId)
                .then(p => {
                    if (mounted.current) {
                        projectUnit.Project = { name: p.name };
                        projectUnit.projectId = p.id;
                        setProjectUnit(projectUnit);
                        setProjectUnitLoading(false);
                        setReload(false);
                        setSubmitting(false);
                    }
                });
        } else {
            ProjectUnit.getById(id)
                .then(item => {
                    console.log(item);
                    if (mounted.current) {
                        if (item.Customers.length > 0 && item.status === 'Available') {
                            setIsDirty(true);
                            item.status = 'Booked';
                        }
                        if (item.Customers.length === 0 && item.status === 'Booked') {
                            setIsDirty(true);
                            item.status = 'Available';
                        }

                        setProjectUnit(item);
                        setDisableDelete(item.Customers && item.Customers.length > 0);
                        setProjectUnitLoading(false);
                        setReload(false);
                        setSubmitting(false);
                    }
                })
                .catch(e => {
                    //console.log(e);
                    window.location.href = "/notfound";
                });
        }
        return () => mounted.current = false;
    }, [reload]);

    useEffect(() => {
        if (isDirty) {
            setAlert(null);
            window.addEventListener("beforeunload", beforeUnloadHandler);
        }
        return () => {
            window.removeEventListener("beforeunload", beforeUnloadHandler);
        }
    }, [isDirty]);

    const beforeUnloadHandler = (event) => {
        event.preventDefault();
        event.returnValue = true;
    };

    const onFieldChanged = (field, value) => {
        if (projectUnit) {
            setIsDirty(true);
            setProjectUnit({ ...projectUnit, [field]: value });
        }
    }

    const onSave = () => {
        setSubmitting(true);
        ProjectUnit.addUpdate(projectUnit)
            .then(item => {
                if (mounted.current) {
                    if (item.error) {
                        return setAlert({ message: item.error, isError: true });
                    }

                    setId(item.id);
                    setIsDirty(false);
                    setProjectUnit(item);
                    setAlert({ message: `Saved`, isError: false });
                    setReload(true);
                }
            });
    }

    const onDelete = () => {
        if (window.confirm("Are you sure you want to delete?") === true) {
            setSubmitting(true);
            setIsDirty(false);
            ProjectUnit.delete(projectUnit).then(item => {
                if (item.error) {
                    setAlert({ message: 'Unable to delete', isError: true });
                    return;
                }
                window.location.href = `/projects/${projectId}/units`
            });
        } else {
            // Do nothing
        }
    }

    const onRemoveCustomerClick = (custId) => {
        if(isDirty) {
            return setAlert({ message: 'Please save before removing customer', isError: true });
        }

        if (window.confirm("Are you sure you want to delete?") === true) {
            setSubmitting(true);
            ProjectUnit.deleteUnitCustomer(projectUnit.id, custId)
                .then(() => {
                    setAlert({ message: 'Customer removed', isError: false });
                    setReload(true);
                });
        } else {
            // Do nothing
        }
    }

    const onAddCustomer = (cid) => {
        if(isDirty) {
            return setAlert({ message: 'Please save before adding customer', isError: true });
        }

        setSubmitting(true);
        ProjectUnit.addUnitCustomer(id, cid)
            .then((item) => {
                if (item.error) {
                    return setAlert({ message: item.error, isError: true });
                }

                setAlert({ message: 'Customer added', isError: false });
                setReload(true);
            });
    }

    const handleDragStart = (event, customer) => {
        event.dataTransfer.setData("text/html", customer.id);
    };

    const handleDragOver = (event, customer) => {
        event.preventDefault();
    };

    const handleDragEnter = (event, customer) => {
        event.preventDefault();
    };

    const handleDragLeave = (event, customer) => {
        event.preventDefault();
    };

    const handleDrop = (event, customer) => {
        event.preventDefault();
        const customerId = event.dataTransfer.getData("text/html");

        if(isDirty) {
            return setAlert({ message: 'Please save before moving customer', isError: true });
        }

        if (window.confirm("Are you sure you want to move the customer?") === true) {
            ProjectUnit.updateUnitCustomer(projectUnit.id, customerId, customer.ProjectUnitCustomer.orderNumber)
                .then(() => {
                    setAlert({ message: 'Customer moved', isError: false });
                    setReload(true);
                });
        }
    };

    return (
        <LoadingPage loading={loading} submitting={submitting}>
            <SearchBar>
                <Row style={{ display: 'flex', alignItems: 'center' }}>
                    <Col sm style={{ display: 'flex', alignItems: 'center', fontStyle: 'bold' }}>
                        <a href='/projects' style={{ paddingRight: '5px', paddingLeft: '5px' }}>Projects</a> /
                        <a href={`/projects/${projectId}/units`} style={{ paddingRight: '5px', paddingLeft: '5px' }}>{projectUnit?.Project?.name}</a> /
                        <span style={{ paddingRight: '5px', paddingLeft: '5px' }}>{projectUnit.name}</span>
                    </Col>
                    {id !== '0' && !disableDelete && <Col sm="auto"><Button variant="outline-danger" onClick={() => onDelete()} title='Delete' disabled={submitting}><Trash size={30} /></Button></Col>}
                    <Col sm="auto"><Button variant="outline-success" onClick={() => onSave()} title='Save' disabled={submitting}><Floppy size={30} /></Button></Col>
                </Row>
            </SearchBar>
            <Content>
                <Row>
                    <Col>
                        <Row>
                            <Col lg>
                                <FormInput value={projectUnit.name || ''} label='Name' onChange={(e) => onFieldChanged('name', e.target.value)} />
                            </Col>
                            <Col lg>
                                <FormInput value={projectUnit.floor || ''} label='Floor' onChange={(e) => onFieldChanged('floor', e.target.value)} />
                            </Col>
                            <Col lg>
                                <FormSelect value={projectUnit.type} indexfield='key' label='Type' field='value' options={projectUnitTypes} onChange={(e) => onFieldChanged('type', e.target.value)} />
                            </Col>
                        </Row>
                        <Row>
                            <Col lg>
                                <FormInput type='number' value={projectUnit.carpetArea || ''} label='Carpet Area' onChange={(e) => onFieldChanged('carpetArea', e.target.value)} />
                            </Col>
                            <Col lg>
                                <FormInput type='number' value={projectUnit.builtUpArea || ''} label='Built Up Area' onChange={(e) => onFieldChanged('builtUpArea', e.target.value)} />
                            </Col>
                            <Col lg>
                                <FormInput type='number' value={projectUnit.superBuiltUpArea || ''} label='Super Built Up Area' onChange={(e) => onFieldChanged('superBuiltUpArea', e.target.value)} />
                            </Col>
                        </Row>
                        <FormInput value={projectUnit.details || ''} label='Details' onChange={(e) => onFieldChanged('details', e.target.value)} as='textarea' style={{ height: '100px' }} />
                        <Row>
                            <Col></Col>
                            <Col lg={4}>
                                <FormSelect value={projectUnit.status} indexfield='key' label='Status' field='value' options={projectUnitStatuses} onChange={(e) => onFieldChanged('status', e.target.value)} />
                            </Col>
                        </Row>
                    </Col>
                    <Col sm='3'>
                        <FileList modelId={id} modelType='projectUnit' />
                    </Col>
                    <Col sm='auto'>
                        {id !== '0' &&
                            <Row className='mb-2'>
                                <Col>
                                    <Card style={{ textAlign: 'center' }}>
                                        <Card.Header>Customer</Card.Header>
                                        <Card.Body style={{ textAlign: 'right' }}>
                                            {projectUnit.Customers && projectUnit.Customers.map((c, i) =>
                                                <Row key={i} style={{ display: 'flex', alignItems: 'center' }}>

                                                    <Col lg='auto'>{c.ProjectUnitCustomer.orderNumber}.</Col>
                                                    <Col lg>
                                                        <Card key={i} className='mb-2' style={{ backgroundColor: '#d0ae6b', cursor: 'pointer', textAlign: 'left' }}
                                                            draggable={true}
                                                            onDragStart={(e) => handleDragStart(e, c)}
                                                            onDragEnter={(e) => handleDragEnter(e, c)}
                                                            onDragLeave={(e) => handleDragLeave(e, c)}
                                                            onDragOver={(e) => handleDragOver(e, c)}
                                                            onDrop={(e) => handleDrop(e, c)}>
                                                            <Card.Body>
                                                                <Row>
                                                                    <Col sm='auto'><ArrowsMove style={{ cursor: 'move' }} className='text-dark' /></Col>
                                                                    <Col sm onClick={() => window.location.href = `/customers/${c.id}`}>{c.name}</Col>
                                                                    <Col sm='auto'><Trash className='text-danger' onClick={() => onRemoveCustomerClick(c.id)} /></Col>
                                                                </Row>
                                                            </Card.Body>
                                                        </Card>
                                                    </Col>
                                                </Row>
                                            )}
                                            <Row style={{ display: 'flex', alignItems: 'center' }}>
                                                <Col lg><FormButton variant='outline-danger' title='Soda Chitthi' onClick={() => window.location.href = `/projects/${projectId}/units/${id}/sc`}><Newspaper size={30}/></FormButton></Col>
                                                <Col lg><FormButton variant='outline-danger' title='Payments' onClick={() => window.location.href = `/projects/${projectId}/units/${id}/payments`}><CurrencyRupee size={30}/></FormButton></Col>
                                                <Col lg><FormButton variant='success' onClick={() => setShowCustomerList(true)}><PlusLg size={30} /></FormButton></Col>
                                            </Row>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>
                        }
                        <CommentList modelId={id} />
                    </Col>
                </Row>
            </Content>

            {alert &&
                <AlertBox isError={alert.isError} onClose={() => setAlert(null)}>
                    {alert.message}
                </AlertBox>}

            {showCustomerList &&
                <AlertBox isError={false} onClose={() => setShowCustomerList(false)}>
                    <div className="ag-theme-quartz" style={{ height: '20rem', width: '28rem' }}>
                        <AgGridReact
                            defaultColDef={defaultColDef}
                            columnDefs={colDefsCustomers}
                            // domLayout="autoHeight"
                            suppressScrollOnNewData='true'
                            rowData={customers}
                        />
                    </div>
                </AlertBox>}
            {/* <div style={{textAlign: 'left'}}><pre>
                {JSON.stringify(project, null, 2)}
            </pre></div> */}
        </LoadingPage>
    )
}