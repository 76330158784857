class User {
  async getRoles() {
    const data = await fetch(`${process.env.REACT_APP_API_URL}/users/roles`, {
      headers: {
        'x-access-token': localStorage.getItem('token')
      }
    });
    return await data.json();
  }

  async login(username, password) {
    const data = await fetch(`${process.env.REACT_APP_API_URL}/users/login`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ username, password })
    });
    return await data.json();
  }

  async get(filter) {
    const data = await fetch(`${process.env.REACT_APP_API_URL}/users?q=${filter}`, {
      headers: {
        'x-access-token': localStorage.getItem('token')
      }
    });
    return await data.json();
  }

  async getById(id) {
    if (id === '0') {
      return {};
    }
    const data = await fetch(`${process.env.REACT_APP_API_URL}/users/${id}`, {
      headers: {
        'x-access-token': localStorage.getItem('token')
      }
    });
    return await data.json();
  }

  async addUpdate(record) {
    const data = await fetch(`${process.env.REACT_APP_API_URL}/users/${record.id || ''}`, {
      method: record.id ? 'PUT' : 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': localStorage.getItem('token')
      },
      body: JSON.stringify(record)
    });
    return await data.json();
  }

  async delete(record) {
    const data = await fetch(`${process.env.REACT_APP_API_URL}/users/${record.id}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': localStorage.getItem('token')
      }
    });
    return await data.json();
  }
}

module.exports = new User();