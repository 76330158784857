import React, { useEffect, useRef, useState } from 'react';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Badge from 'react-bootstrap/Badge';
import Project from '../../services/project';
import { ArrowRepeat, PlusLg } from 'react-bootstrap-icons';
import SearchBar from '../SearchBar';
import Content from '../Content';
import AlertBox from '../AlertBox';
import LoadingPage from '../LoadingPage';
import FormButton from '../FormButton';
import ProjectImage from '../ProjectImage';

export default function PageProject() {
    const [reload, setReload] = useState(true);
    const [alert, setAlert] = useState(null);
    const [list, setList] = useState([]);
    const [searchText, setSearchText] = useState('');
    const mounted = useRef(true);

    const [submitting, setSubmitting] = useState(false);

    const [loading, setLoading] = useState(true);
    const [listLoading, setListLoading] = useState(true);

    useEffect(() => {
        setLoading(listLoading)
    }, [listLoading]);

    useEffect(() => {
        setSubmitting(false);
    }, [alert]);

    useEffect(() => { document.title = "Projects"; }, []);
    useEffect(() => {
        window.addEventListener("focus", () => setReload(true))
        return () => { window.removeEventListener("focus", () => setReload(true)); }
    }, []);
    
    useEffect(() => {
        mounted.current = true;
        if (list.length && !reload) {
            return;
        }

        Project.get(searchText)
            .then(items => {
                if (mounted.current) {
                    setList(items)
                    setReload(false);
                    setListLoading(false);
                }
            })
        return () => mounted.current = false;
    }, [reload]);

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            setReload(true);
          }, 1000);
      
          return () => clearTimeout(delayDebounceFn);
    }, [searchText]);

    

    const onProjectDetailsClicked = (id) => {
        window.location.href = `/projects/${id}`
    }

    const onNewClicked = (id) => {
        window.location.href = `/projects/0`
    }

    const onProjectUnitsClicked = (id) => {
        window.location.href = `/projects/${id}/units`
    }

    return (
        <LoadingPage loading={loading} submitting={submitting}>
            <SearchBar>
                <Row style={{ display: 'flex', alignItems: 'center' }}>
                    <Col sm="auto"><Badge bg="danger" style={{fontSize:'1.2rem'}}>{list.length}</Badge></Col>
                    <Col sm><Form.Control type="search" placeholder="Search" aria-label="Search project" aria-describedby="basic-addon2" value={searchText} onChange={(e) => setSearchText(e.target.value)} /></Col>
                    <Col sm="auto"><Button variant="outline-success" onClick={() => onNewClicked()} title='Add new'><PlusLg size={30} /></Button></Col>
                    <Col sm="auto"><FormButton variant="outline-secondary" onClick={() => setReload(true)} title='Refresh' loading={reload}><ArrowRepeat size={30} /></FormButton></Col>
                </Row>
            </SearchBar>
            <Content>
                <Row xs={3} md={6}  className="justify-content-md-center g-4">
                    {list.map((item, i) =>
                        <Col key={i}>
                            <Card className="text-center" data-bs-theme="dark" style={{borderWidth: '8px', borderColor: '#D0AE6B', borderStyle: 'double', borderRadius: '12px'}}>
                                <ProjectImage id={item.id} />
                                <Card.Body style={{borderTopWidth: '1px', borderTopColor: '#D0AE6B', borderTopStyle: 'solid'}}>
                                    <Card.Title>{item.name}</Card.Title>
                                    
                                    <Button className='m-2' onClick={() => onProjectDetailsClicked(item.id)}>
                                        Details
                                    </Button>
                                    <Button className='m-2' onClick={() => onProjectUnitsClicked(item.id)}>
                                        Units <Badge bg="secondary">{item.ProjectUnits.length}</Badge>
                                    </Button>
                                    
                                </Card.Body>
                                <Card.Footer className="text-muted">0 / {item.ProjectUnits.length}</Card.Footer>
                            </Card>
                        </Col>
                    )}
                </Row>
            </Content>

            {alert &&
                <AlertBox isError={alert.isError} onClose={() => setAlert(null)}>
                    {alert.message}
                </AlertBox>}
        </LoadingPage>
    );
}