class ProjectUnitPayments {
  async getMarginTypes() {
    const data = await fetch(`${process.env.REACT_APP_API_URL}/projectunitpayments/margintypes`, {
      headers: {
        'x-access-token': localStorage.getItem('token')
      }
    });
    return await data.json();
  }

  async get(projectUnitId) {
    const data = await fetch(`${process.env.REACT_APP_API_URL}/projectunitpayments/${projectUnitId}`, {
      headers: {
        'x-access-token': localStorage.getItem('token')
      }
    });
    return await data.json();
  }

  async add(record) {
    const data = await fetch(`${process.env.REACT_APP_API_URL}/projectunitpayments/${record.projectUnitId}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': localStorage.getItem('token')
      },
      body: JSON.stringify(record)
    });
    return await data.json();
  }

  async delete(paymentId) {
    const data = await fetch(`${process.env.REACT_APP_API_URL}/projectunitpayments/${paymentId}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': localStorage.getItem('token')
      }
    });
    return await data.json();
  }
}

module.exports = new ProjectUnitPayments();