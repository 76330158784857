import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';

export default function AlertBox(params) {
    return (
        // <Alert variant={params.isError ? 'danger' : 'success'} style={{ position: 'fixed', minWidth: '25rem', display: 'flex', bottom: '2.5rem', right: '3rem', zIndex: '9999' }} onClose={params.onClose} dismissible>
        //     {params.children}
        // </Alert>

        <Modal show={params.children} onHide={params.onClose} backdrop="static" keyboard={false}>
            <Modal.Header style={{ backgroundColor: params.isError ? '#dc3545' : '#198754', color: 'white' }}>
                <Modal.Title>{params.title || 'Message'}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {/* <Form.Control placeholder="New subject name" aria-label="New subject name" value={newSubject['Subject Name']} onChange={(e) => setNewSubject({ ['Subject Name']: e.target.value })} /> */}
                <Row className='justify-content-md-center'>
                    <Col md="auto">
                        {params.children}
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="outline-dark" onClick={params.onClose} title='OK'>OK</Button>
            </Modal.Footer>
        </Modal>
    );
}