import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import packageJson from '../../../package.json';

export default function Footer() {
    return (
        <Navbar expand="lg" fixed='bottom' data-bs-theme="dark" style={{ backgroundColor: '#D0AE6B' }}>
            <Container>
                <Nav className="me-auto">
                    <div>&copy; nybble Technologies</div>
                </Nav>

                <Nav className="">
                    <div>Version {packageJson.version}</div>
                </Nav>
            </Container>
        </Navbar>
    )
}