import React from "react";
import { Trash } from 'react-bootstrap-icons';
import Button from 'react-bootstrap/Button';

export default (params) => {
  return (
    <div>
      <Button variant="outline-danger" size="sm" className="mb-1"  onClick={params.onClick}><Trash /></Button>
    </div>
  )
}