import { useEffect, useState } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import './index.css';

import Header from '../Header';
import Footer from '../Footer';
import PageError from '../PageError';
import PageNotFound from '../PageNotFound';
import PageLogin from '../PageLogin';
import PageProject from '../PageProject';
import PageSettings from '../PageSettings';
import PageProjectEdit from '../PageProjectEdit';
import PageProjectUnits from '../PageProjectUnits';
import PageProjectUnitEdit from '../PageProjectUnitEdit';
import PageProjectUnitSodaChitthi from '../PageProjectUnitSodaChitthi';
import PageProjectUnitPayments from '../PageProjectUnitPayments';
import PageCustomer from '../PageCustomer';
import PageCustomerEdit from '../PageCustomerEdit';
import PageTemplate from '../PageTemplate';
import PageFileFromTemplate from '../PageFileFromTemplate';
import PageGlobal from '../PageGlobal';
import PageUsers from '../PageUsers';
import PageUserEdit from '../PageUserEdit';
import AuthGuard from '../AuthGuard';
import PI from '../../services/pi';
import packageJson from '../../../package.json';
import PageMaintenance from '../PageMaintenance';
import PageBroker from '../PageBroker';
import PageBrokerEdit from '../PageBrokerEdit';

function App() {
  const [isVersionMatched, setIsVersionMatched] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    async function checkVersion() {
      PI.get().then(data => {
        if (data.version !== packageJson.version) {
          setIsVersionMatched(false);
        }
      }).catch(() => {
        setIsVersionMatched(false);
      });
    }

    checkVersion();
  }, [navigate]);

  if (!isVersionMatched) {
    return <PageMaintenance />;
  }

  return (
    <div className="App">
        <Header></Header>
          <Routes>
            {/* <Route exact path="/" element={<Home />} /> */}
            <Route exact path="/" element={<PageLogin />} />
            <Route exact path="/login" element={<PageLogin />} />
            <Route exact path="/projects" element={<AuthGuard><PageProject /></AuthGuard>} />
            <Route exact path="/projects/:id" element={<AuthGuard><PageProjectEdit /></AuthGuard>} />
            <Route exact path="/projects/:projectId/units" element={<AuthGuard><PageProjectUnits /></AuthGuard>} />
            <Route exact path="/projects/:projectId/units/:id" element={<AuthGuard><PageProjectUnitEdit /></AuthGuard>} />
            <Route exact path="/projects/:projectId/units/:id/sc" element={<AuthGuard><PageProjectUnitSodaChitthi /></AuthGuard>} />
            <Route exact path="/projects/:projectId/units/:id/payments" element={<AuthGuard><PageProjectUnitPayments /></AuthGuard>} />
            <Route exact path="/customers" element={<AuthGuard><PageCustomer /></AuthGuard>} />
            <Route exact path="/customers/:id" element={<AuthGuard><PageCustomerEdit /></AuthGuard>} />
            <Route exact path="/brokers" element={<AuthGuard><PageBroker /></AuthGuard>} />
            <Route exact path="/brokers/:id" element={<AuthGuard><PageBrokerEdit /></AuthGuard>} />
            <Route exact path="/templates" element={<AuthGuard><PageTemplate /></AuthGuard>} />
            <Route exact path="/templates/new" element={<AuthGuard><PageFileFromTemplate /></AuthGuard>} />
            <Route exact path="/global" element={<AuthGuard><PageGlobal /></AuthGuard>} />
            <Route exact path="/users" element={<AuthGuard><PageUsers /></AuthGuard>} />
            <Route exact path="/users/:id" element={<AuthGuard><PageUserEdit /></AuthGuard>} />
            <Route exact path="/settings" element={<AuthGuard><PageSettings /></AuthGuard>} />
            <Route path="/error" element={<PageError />} />
            <Route path="/notfound" element={<PageNotFound />} />
            <Route path="*" element={<PageNotFound />} />
          </Routes>
      <br />
      <br />
      <footer id='footer'>
        <Footer></Footer>
      </footer>
    </div>
  );
}

export default App;
