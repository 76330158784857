import Form from 'react-bootstrap/Form';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import './index.css';

export default function FormText(props) {
    var isValid = !props.valid || props.valid === 'true' ? true : false;
    var title = props.title || '';
    var isCurrency = props.children && props.children.includes('₹');
    var text = props.children && props.children.replace(/[₹,]/g, '');
    var minvalue = props.minvalue;
    var maxvalue = props.maxvalue;
    var equalto = props.equalto;

    const toINR = (val) => {
        return val ? parseFloat(val).toLocaleString('en-IN', { style: 'currency', currency: 'INR' }) : '';
    }

    if (props.percentage && isValid) {
        minvalue = minvalue || '0';
        maxvalue = maxvalue || '100';
    }

    if (minvalue && isValid) {
        if (!text || parseFloat(text) < parseFloat(minvalue)) {
            isValid = false;
            title = `Value must be greater than or equal to ${isCurrency ? toINR(minvalue) : minvalue}`;
        }
    }

    if (maxvalue && isValid) {
        if (!text || parseFloat(text) > parseFloat(maxvalue)) {
            isValid = false;
            title = `Value must be less than or equal to ${isCurrency ? toINR(maxvalue) : maxvalue}`;
        }
    }

    if (equalto && isValid) {
        if (!text || parseFloat(text) !== parseFloat(equalto)) {
            isValid = false;
            title = `Value must be equal to ${isCurrency ? toINR(equalto) : equalto}`;
        }
        
    }

    return (
        <span className={`${isValid ? '' : 'form-text-error'}`} title={title}>
            {props.children}
        </span>
    )
}