import React from "react";
import "./index.css"

export default (params) => {
  return (
    <>
      {params.title &&
        <div className="CellLinkRenderer">
          <a href='#' target="_self" onClick={params.onClick}>
            {params.title}
          </a>
        </div>
      }
    </>
  )
}