import Spinner from 'react-bootstrap/Spinner';
import Content from '../Content';

export default ({ loading, submitting, children }) => {
    return (
        <>
            <div style={{ textAlign: 'center', paddingTop: '10rem' }} hidden={!loading}>
                <Spinner animation="border" role="status" variant="secondary">
                    <span className="visually-hidden">Loading...</span>
                </Spinner>
            </div>
            <div hidden={loading}>
                {children}
            </div>

            <div hidden={!submitting}>
                <div style={{ position: 'absolute', top: '0', bottom: '0', right: '0', left: '0', backgroundColor: 'grey', textAlign: 'center', opacity: 0.5 }}>
                </div>
                <div style={{ textAlign: 'center', paddingTop: '10rem' }}>
                    <Spinner animation="border" role="status" variant="primary">
                        <span className="visually-hidden">Loading...</span>
                    </Spinner>
                </div>
            </div>
        </>
    );
}
