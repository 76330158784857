import React, { useRef, useState } from "react";
import moment from "moment";
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Button from 'react-bootstrap/Button';
import FormControl from 'react-bootstrap/FormControl';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Toast from 'react-bootstrap/Toast';
import { Trash, CheckLg, XLg, Pencil } from 'react-bootstrap-icons';
import Comment from "../../../services/comment";

export default (params) => {
    const mounted = useRef(true);
    const [comment, setComment] = useState(params.comment);
    const [isEdit, setIsEdit] = useState(false);
    const [submitting, setSubmitting] = useState(false);

    const onDeleteClick = (comment) => {
        if (window.confirm("Are you sure you want to delete the comment?") === true) {
            setSubmitting(true);
            Comment.delete(comment)
                .then(() => { params.onChange(); setSubmitting(false); });
        }
    }

    const onSaveClick = (comment) => {
        setSubmitting(true);
        Comment.update(comment)
            .then(() => { setIsEdit(false); params.onChange(); setSubmitting(false); });
    }

    return (
        <>
            <Toast bg='light' key={comment.id}>
                <Toast.Header closeButton={false}>
                    <strong className="me-auto"></strong>
                    <small className="text-muted">{moment(comment.createdAt).format('YYYY-MM-DD HH:mm:ss')}</small>
                </Toast.Header>
                <Toast.Body>
                    {!isEdit && <Row>
                        <Col>{comment.details}</Col>
                        <Col sm='auto'><a href="#" onClick={() => setIsEdit(true)} disabled={submitting}><Pencil className="text-success" /></a></Col>
                        <Col sm='auto'><a href="#" onClick={() => onDeleteClick(comment)} disabled={submitting}><Trash className="text-danger" /></a></Col>
                    </Row>}
                    {isEdit && <Row>
                        <Col><FormControl type="search" value={comment.details} onChange={(e) => setComment({ ...comment, details: e.target.value })} disabled={submitting} /></Col>
                        <Col sm='auto'>
                            <ButtonGroup>
                                <Button variant='success' onClick={() => onSaveClick(comment)} disabled={submitting}><CheckLg /></Button>
                                <Button variant='danger' onClick={() => setIsEdit(false)} disabled={submitting}><XLg /></Button>
                            </ButtonGroup>
                        </Col>
                    </Row>}
                </Toast.Body>
            </Toast>
        </>
    )
}