import React, { useState } from "react";
import InputGroup from 'react-bootstrap/InputGroup';
import Form from 'react-bootstrap/Form';
import { ChevronDown, ChevronRight } from "react-bootstrap-icons";
import moment from 'moment';

export default function JsonTree({ field, value, skipKeys = []}) {
    const [expand, setExpand] = useState(false);

    const handleDragStart = (event, text) => {
        event.dataTransfer.setData('text/plain', text);
    };

    const handleDragOver = (event) => {
        // event.preventDefault();
    };

    const handleDrop = (event, field) => {
        // event.preventDefault();
        // const text = event.dataTransfer.getData('text/plain');
        // setIsDirty(true);
        // setTags({ ...tags, [field]: text });
    };

    if (value === null || skipKeys.includes(field)) {
        return;
    }
    if (!isNaN(field)) {
        field = `${Number(field)+1}: ${value.name || ''}`;
    }
    if (field.toLowerCase().includes("date")) {
        value = moment(value).format("DD-MM-YYYY");
    }
    if (typeof value !== "object") {
        return (
            <InputGroup className="mb-2" draggable="true" style={{ cursor: 'move' }} onDragStart={(e) => handleDragStart(e, value)}>
                <InputGroup.Text style={{ textTransform: 'capitalize' }}>{field}</InputGroup.Text>
                <Form.Control style={{ cursor: 'move' }} value={value} disabled={true} />
            </InputGroup>
        );
    }

    const filteredValue = Object.keys(value).reduce((acc, key) => {
        if (skipKeys.includes(key)) {
            return acc;
        }
        acc[key] = value[key];
        return acc;
    }, {});
    return (
        <div>
            <InputGroup className="mb-2" onClick={() => setExpand(!expand)} draggable="true" style={{ cursor: 'move' }} onDragStart={(e) => handleDragStart(e, JSON.stringify(filteredValue))}>
                <InputGroup.Text style={{ backgroundColor: '#d0ae6b', textTransform: 'capitalize' }}>{expand ? <ChevronDown /> : <ChevronRight />} {field}</InputGroup.Text>
            </InputGroup>
            <div style={{ display: expand ? "block" : "none", paddingLeft: 15 }} >
                {
                    Object.entries(value).map(([key, value]) => (
                        <JsonTree key={key} field={key} value={value} skipKeys={skipKeys} />
                    ))
                }
            </div>
        </div>
    );
}