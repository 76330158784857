import React, { useEffect, useMemo, useRef, useState } from 'react';
import { ArrowRepeat, Floppy, PlusLg, Trash } from 'react-bootstrap-icons';
import Button from 'react-bootstrap/Button';
import Global from '../../services/global';
import Content from '../Content';
import SearchBar from '../SearchBar';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import AlertBox from '../AlertBox';
import LoadingPage from '../LoadingPage';
import FormButton from '../FormButton';
import FormInput from '../FormInput';
import Utils from '../../utils';

export default () => {
    const mounted = useRef(true);
    const [reload, setReload] = useState(true);
    const [globalValues, setGlobalValues] = useState([]);
    const [newGlobalValue, setNewGlobalValue] = useState({});
    const [isDirty, setIsDirty] = useState(false);
    const [alert, setAlert] = useState(null);

    const [submitting, setSubmitting] = useState(false);

    const [loading, setLoading] = useState(true);
    const [globalValuesLoading, setGlobalValuesLoading] = useState(true);

    useEffect(() => { document.title = "Global"; }, []);

    useEffect(() => {
        setLoading(globalValuesLoading);
    }, [globalValuesLoading]);

    useEffect(() => {
        setSubmitting(false);
    }, [alert]);

    const [colDefs, setColDefs] = useState([
        { field: 'key', flex: 1 },
        { field: 'value', flex: 5, editable: true },
    ]);
    const defaultColDef = useMemo(() => {
        return {
            flex: 1,
            comparator:  Utils.defaultComparator,
        };
    }, []);

    useEffect(() => {
        mounted.current = true;
        if (!reload) {
            return;
        }

        Global.get()
            .then(items => {
                if (mounted.current) {
                    setGlobalValues(items);
                    setReload(false);
                    setGlobalValuesLoading(false);
                }
            });
        return () => mounted.current = false;
    }, [reload]);

    useEffect(() => {
        if (isDirty) {
            setAlert(null);
            window.removeEventListener("beforeunload", beforeUnloadHandler);
            window.addEventListener("beforeunload", beforeUnloadHandler);
        }
        return () => {
            window.removeEventListener("beforeunload", beforeUnloadHandler);
        }
    }, [isDirty]);

    const beforeUnloadHandler = (event) => {
        event.preventDefault();
        event.returnValue = true;
    };

    const onSave = () => {
        setSubmitting(true);
        Global.addUpdate(globalValues)
            .then(item => {
                if (item.error) {
                    return setAlert({ message: item.error, isError: true });
                }

                setIsDirty(false);
                setReload(true);
                setAlert({ message: `Saved`, isError: false });
            });
    }

    const onNewClick = () => {
        if (globalValues.findIndex(f => f.key === newGlobalValue.key) !== -1) {
            return setAlert({ message: 'Key already exists', isError: true });
        }
        setGlobalValues([...globalValues, newGlobalValue]);
        setNewGlobalValue({});
        setIsDirty(true);
    }

    const onChangeValue = (i, key, value) => {
        const newGlobalValues = [...globalValues];
        newGlobalValues[i] = { ...newGlobalValues[i], key: key, value: value };
        setGlobalValues(newGlobalValues);
        setIsDirty(true);
    }

    const onDeleteClick = (i) => {
        if (isDirty) {
            return setAlert({ message: 'Save changes before deleting', isError: true });
        }
        if (window.confirm("Are you sure you want to delete?") === true) {
            setSubmitting(true);
            Global.delete(globalValues[i])
                .then(() => {
                    setReload(true);
                    setAlert({ message: 'Global value deleted', isError: false });
                });
        }
    }

    return (
        <LoadingPage loading={loading} submitting={submitting}>
            <SearchBar>
                <Row style={{ display: 'flex', alignItems: 'center' }}>
                    <Col sm style={{ display: 'flex', alignItems: 'center', fontStyle: 'bold' }}>Global</Col>
                    <Col sm="auto"><Button variant="outline-success" onClick={() => onSave()} title='Save' disabled={submitting}><Floppy size={30} /></Button></Col>
                    <Col sm="auto"><FormButton variant="outline-secondary" onClick={() => setReload(true)} title='Refresh' loading={reload} disabled={submitting}><ArrowRepeat size={30} /></FormButton></Col>
                </Row>
            </SearchBar>
            <Content>
                <Row>
                    <InputGroup className="mb-4">
                        <Form.Control placeholder='key' value={newGlobalValue.key || ''} label='Key' onChange={(e) => setNewGlobalValue({ ...newGlobalValue, key: e.target.value })} />
                        <Form.Control placeholder='value' value={newGlobalValue.value || ''} label='Value' onChange={(e) => setNewGlobalValue({ ...newGlobalValue, value: e.target.value })} />
                        <Button variant='success' onClick={onNewClick}><PlusLg size={20} /></Button>
                    </InputGroup>
                </Row>

                {globalValues.map((g, i) =>
                    <InputGroup key={i} className="mb-2">
                        <InputGroup.Text>{g.key}</InputGroup.Text>
                        <Form.Control value={g.value || ''} onChange={(e) => onChangeValue(i, g.key, e.target.value)}/>
                        <Button variant='danger' onClick={() => onDeleteClick(i)}><Trash size={20} /></Button>
                    </InputGroup>
                )}
            </Content>

            {alert &&
                <AlertBox isError={alert.isError} onClose={() => setAlert(null)}>
                    {alert.message}
                </AlertBox>}
        </LoadingPage>
    );
}