export default function Content(params) {
    return (
        <div style={{
            position: 'fixed',
            width: '100%',
            top: params.top || '7.4rem',
            bottom: params.bottom || '2.5rem',
            overflowY: (params.overflowy || 'scroll'),
            padding: params.padding || '1rem',
            textAlign: (params.textalign || 'left'),
            opacity: (params.opacity || 1),
        }} {...params}>
            {params.children}
        </div>
    );
}