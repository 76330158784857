import { Bug } from "react-bootstrap-icons";

export default function Error() {
    return (
        <>
            <div style={{ textAlign: 'center' }}>
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <Bug size={100} />
                <br />
                Error
                <br />
            </div>
        </>
    );
}