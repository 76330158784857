import React from "react";
import { Floppy, Trash, Check, XLg, CheckCircleFill, XCircleFill } from 'react-bootstrap-icons';
import Button from 'react-bootstrap/Button';

export default (params) => {
  return (
    <div>
      {params.error ? <XCircleFill className="text-danger"/> : <CheckCircleFill className="text-success"/>}
    </div>
  )
}