class PI {
  async get() {
    const data = await fetch(`${process.env.REACT_APP_API_URL}/pi`, {
      headers: {
        
      }
    });
    return await data.json();
  }
}

module.exports = new PI();