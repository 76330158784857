import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router';
import moment from 'moment';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table';
import Offcanvas from 'react-bootstrap/Offcanvas';
import InputGroup from 'react-bootstrap/InputGroup';
import ProjectUnit from '../../services/projectUnit';
import { Calculator, CurrencyRupee, DistributeVertical, Floppy, PlusLg, Printer, Trash } from 'react-bootstrap-icons';
import FormSelect from '../../components/FormSelect';
import FormInput from '../../components/FormInput';
import FormButton from '../../components/FormButton';
import SearchBar from '../SearchBar';
import Content from '../Content';
import AlertBox from '../AlertBox';
import LoadingPage from '../LoadingPage';
import './index.css';
import FormText from '../FormText';
import Broker from '../../services/broker';

export default function PageProjectUnitEdit() {
    const mounted = useRef(true);
    const [projectId, setProjectId] = useState(useParams().projectId);
    const [id, setId] = useState(useParams().id);
    const [isDirty, setIsDirty] = useState(false);
    const [reload, setReload] = useState(true);
    const [alert, setAlert] = useState(null);
    const [projectUnit, setProjectUnit] = useState({});
    const [brokers, setBrokers] = useState([]);
    const [areaTypes, setAreaTypes] = useState([]);
    const [dealAmount, setDealAmount] = useState(0.00);
    const [cashAmount, setCashAmount] = useState(0.00);
    const [gstAmount, setGstAmount] = useState(0.00);
    const [stampDutyAmount, setStampDutyAmount] = useState(0.00);
    const [totalDeedAmount, setTotalDeedAmount] = useState(0.00); // cheque amount
    const [totalCostAdditions, setTotalCostAdditions] = useState(0.00);
    const [totalAmount, setTotalAmount] = useState(0.00);
    const [effectivePricePerSqft, setEffectivePricePerSqft] = useState(0.00);
    const [chequeBalanceAmount, setChequeBalanceAmount] = useState(0.00);
    const [cashBalanceAmount, setCashBalanceAmount] = useState(0.00);
    const [chequeTokenAmountPercentage, setChequeTokenAmountPercentage] = useState(0.00);
    const [cashTokenAmountPercentage, setCashTokenAmountPercentage] = useState(0.00);
    const [chequeMarginAmountPercentage, setChequeMarginAmountPercentage] = useState(0.00);
    const [cashMarginAmountPercentage, setCashMarginAmountPercentage] = useState(0.00);
    const [balanceTillSaleDeedPercentage, setBalanceTillSaleDeedPercentage] = useState(0.00);
    const [chequeBalanceAmountPercentage, setChequeBalanceAmountPercentage] = useState(0.00);
    const [cashBalanceAmountPercentage, setCashBalanceAmountPercentage] = useState(0.00);

    const [isManualChange, setIsManualChange] = useState(false);

    const [chequeInstallments, setChequeInstallments] = useState([]);
    const [cashInstallments, setCashInstallments] = useState([]);
    const [chequeRecalculateInstallments, setChequeRecalculateInstallments] = useState(false);
    const [cashRecalculateInstallments, setCashRecalculateInstallments] = useState(false);

    const [costAdditionName, setCostAdditionName] = useState('');
    const [costAdditionAmount, setCostAdditionAmount] = useState('');

    const [showAdditionList, setShowAdditionList] = useState(false);
    const [showCalculations, setShowCalculations] = useState(false);

    const [submitting, setSubmitting] = useState(false);

    const [loading, setLoading] = useState(true);
    const [projectUnitLoading, setProjectUnitLoading] = useState(true);
    const [brokerLoading, setBrokerLoading] = useState(true);

    const [printPreview, setPrintPreview] = useState(false);

    useEffect(() => {
        if (isDirty) {
            setAlert({ message: 'Save changes before printing', isError: true });
        } else if (printPreview) {
            window.print();
        }
        setPrintPreview(false);
    }, [printPreview]);

    useEffect(() => {
        setLoading(projectUnitLoading || brokerLoading);
    }, [projectUnitLoading, brokerLoading]);

    useEffect(() => {
        setSubmitting(false);
    }, [alert]);

    useEffect(() => { document.title = "Soda Chitthi"; }, []);

    useEffect(() => {
        ProjectUnit.getAreaTypes()
            .then(items => {
                setAreaTypes(items);
            });
    }, []);

    useEffect(() => {
        Broker.get('')
            .then(items => {
                if (items.error) {
                    return setAlert({ message: items.error, isError: true });
                }
                items.unshift({ id: 0, name: 'Select Broker' });
                setBrokers(items);
                setBrokerLoading(false);
            });
    }, []);

    useEffect(() => {
        mounted.current = true;
        if (!reload) {
            return;
        }

        ProjectUnit.getById(id)
            .then(item => {
                // set first time values
                item.sodaChitthiDate = item.sodaChitthiDate || moment().format('YYYY-MM-DD');
                item.chequeTokenAmountEndDate = item.chequeTokenAmountEndDate || moment().format('YYYY-MM-DD');
                item.cashTokenAmountEndDate = item.cashTokenAmountEndDate || moment().format('YYYY-MM-DD');
                item.chequeMarginAmountEndDate = item.chequeMarginAmountEndDate || moment().format('YYYY-MM-DD');
                item.cashMarginAmountEndDate = item.cashMarginAmountEndDate || moment().format('YYYY-MM-DD');
                item.paymentTermsEndDate = item.paymentTermsEndDate || moment().format('YYYY-MM-DD');

                const newChequeInstallments = item.ProjectUnitInstallments ? item.ProjectUnitInstallments.filter(x => x.marginType === 'Cheque') : [];
                const newCashInstallments = item.ProjectUnitInstallments ? item.ProjectUnitInstallments.filter(x => x.marginType === 'Cash') : [];

                setChequeInstallments(newChequeInstallments);
                setCashInstallments(newCashInstallments);

                item.areaType = item.areaType || 'SBA';

                setProjectUnit(item);
                setProjectUnitLoading(false);
                setReload(false);
                setSubmitting(false);
            })
            .catch(e => {
                window.location.href = "/notfound";
            });

        return () => mounted.current = false;
    }, [reload]);

    useEffect(() => {
        if (isDirty) {
            setAlert(null);
            window.addEventListener("beforeunload", beforeUnloadHandler);
        }
        return () => {
            window.removeEventListener("beforeunload", beforeUnloadHandler);
        }
    }, [isDirty]);

    useEffect(() => {
        if (isManualChange) {
            var newArea = projectUnit.areaType === 'SBA' ? parseFloat(projectUnit.superBuiltUpArea) : projectUnit.areaType === 'BA' ? parseFloat(projectUnit.builtUpArea) : parseFloat(projectUnit.carpetArea);
            const newPricePerSqFt = parseFloat(dealAmount) / parseFloat(newArea);
            setProjectUnit({ ...projectUnit, pricePerSqft: newPricePerSqFt });
            setIsManualChange(false);
        }
    }, [dealAmount]);

    useEffect(() => {
        if (isManualChange) {
            const newSaleDeedAmount = (parseFloat(dealAmount) - cashAmount).toFixed(0);
            setProjectUnit({ ...projectUnit, saleDeedAmount: newSaleDeedAmount });
            setIsManualChange(false);
        }
    }, [cashAmount]);

    // calculations
    useEffect(() => {
        var newArea = projectUnit.areaType === 'SBA' ? parseFloat(projectUnit.superBuiltUpArea) : projectUnit.areaType === 'BA' ? parseFloat(projectUnit.builtUpArea) : parseFloat(projectUnit.carpetArea);

        var newDealAmount = dealAmount;
        var newCashAmount = cashAmount;

        if (!isManualChange) {
            newDealAmount = (parseFloat(newArea) * parseFloat(projectUnit.pricePerSqft)).toFixed(0);
            setDealAmount(newDealAmount);

            newCashAmount = (parseFloat(newDealAmount) - parseFloat(projectUnit.saleDeedAmount)).toFixed(0);
            setCashAmount(newCashAmount);
        }

        const newGstAmount = toFixed(parseFloat(projectUnit.saleDeedAmount) * parseFloat(projectUnit.gstPercentage) / 100.0);
        const newStampDutyAmount = toFixed(parseFloat(projectUnit.saleDeedAmount) * parseFloat(projectUnit.stampDutyPercentage) / 100.0);
        const newTotalCostAdditions = toFixed(parseFloat(newGstAmount) + parseFloat(newStampDutyAmount) + projectUnit?.ProjectUnitCostAdditions?.reduce((a, b) => a + parseFloat(b.amount), 0));
        const newTotalDeedAmount = toFixed(parseFloat(projectUnit.saleDeedAmount) + parseFloat(newTotalCostAdditions));
        const newTotalAmount = toFixed(parseFloat(newTotalDeedAmount) + parseFloat(newCashAmount));
        const newEffectivePricePerSqft = toFixed(parseFloat(newTotalAmount) / parseFloat(projectUnit.superBuiltUpArea));

        const newChequeBalanceAmount = toFixed(parseFloat(newTotalDeedAmount) - parseFloat(projectUnit.chequeTokenAmount) - parseFloat(projectUnit.chequeMarginAmount) - parseFloat(projectUnit.balanceTillSaleDeed));
        const newCashBalanceAmount = toFixed(parseFloat(newCashAmount) - parseFloat(projectUnit.cashTokenAmount) - parseFloat(projectUnit.cashMarginAmount));

        const newChequeTokenAmountPercentage = toFixed(parseFloat(projectUnit.chequeTokenAmount) / parseFloat(newTotalDeedAmount) * 100.0);
        const newCashTokenAmountPercentage = toFixed(parseFloat(projectUnit.cashTokenAmount) / parseFloat(newCashAmount) * 100.0);
        const newChequeMarginAmountPercentage = toFixed(parseFloat(projectUnit.chequeMarginAmount) / parseFloat(newTotalDeedAmount) * 100.0);
        const newCashMarginAmountPercentage = toFixed(parseFloat(projectUnit.cashMarginAmount) / parseFloat(newCashAmount) * 100.0);
        const newBalanceTillSaleDeedPercentage = toFixed(parseFloat(projectUnit.balanceTillSaleDeed) / parseFloat(newTotalDeedAmount) * 100.0);

        const newChequeBalanceAmountPercentage = toFixed(parseFloat(newChequeBalanceAmount) / parseFloat(newTotalDeedAmount) * 100.0);
        const newCashBalanceAmountPercentage = toFixed(parseFloat(newCashBalanceAmount) / parseFloat(newCashAmount) * 100.0);

        setGstAmount(newGstAmount);
        setStampDutyAmount(newStampDutyAmount);
        setTotalCostAdditions(newTotalCostAdditions);
        setTotalDeedAmount(newTotalDeedAmount);
        setTotalAmount(newTotalAmount);
        setEffectivePricePerSqft(newEffectivePricePerSqft);

        setChequeBalanceAmount(newChequeBalanceAmount);
        setCashBalanceAmount(newCashBalanceAmount);

        setChequeTokenAmountPercentage(newChequeTokenAmountPercentage);
        setCashTokenAmountPercentage(newCashTokenAmountPercentage);
        setChequeMarginAmountPercentage(newChequeMarginAmountPercentage);
        setCashMarginAmountPercentage(newCashMarginAmountPercentage);
        setBalanceTillSaleDeedPercentage(newBalanceTillSaleDeedPercentage);
        setChequeBalanceAmountPercentage(newChequeBalanceAmountPercentage);
        setCashBalanceAmountPercentage(newCashBalanceAmountPercentage);

        if (chequeBalanceAmount > 0 && chequeInstallments.length === 0) {
            const newChequeInstallments = [{ marginType: 'Cheque', date: moment().format('YYYY-MM-DD'), amount: parseFloat(newChequeBalanceAmount) }];
            setChequeInstallments(newChequeInstallments);
            setProjectUnit({ ...projectUnit, ProjectUnitInstallments: [...newChequeInstallments, ...cashInstallments] });
        }

        if (cashBalanceAmount > 0 && cashInstallments.length === 0) {
            const newCashInstallments = [{ marginType: 'Cash', date: moment().format('YYYY-MM-DD'), amount: parseFloat(newCashBalanceAmount) }];
            setCashInstallments(newCashInstallments);
            setProjectUnit({ ...projectUnit, ProjectUnitInstallments: [...chequeInstallments, ...newCashInstallments] });
        }
    }, [projectUnit]);

    useEffect(() => {
        if (parseFloat(chequeBalanceAmount) > 0) {
            const newChequeNoOfInstallments = chequeInstallments.length;

            // sum all but last amount and add the balance to the last installment
            const sumOfInstallmentsExceptLast = chequeInstallments.slice(0, chequeInstallments.length - 1).reduce((a, b) => a + parseFloat(b.amount), 0);
            const lastInstallmentAmount = parseFloat(chequeBalanceAmount) - parseFloat(sumOfInstallmentsExceptLast);

            const newChequeInstallments = chequeInstallments.map((item, i) => i === newChequeNoOfInstallments - 1 ? { ...item, amount: lastInstallmentAmount } : item);
            setChequeInstallments(newChequeInstallments);
            setProjectUnit({ ...projectUnit, ProjectUnitInstallments: [...newChequeInstallments, ...cashInstallments] });
        }

        setChequeRecalculateInstallments(false);
    }, [chequeBalanceAmount, chequeRecalculateInstallments]);

    useEffect(() => {
        if (parseFloat(cashBalanceAmount) > 0) {
            const newCashNoOfInstallments = cashInstallments.length;

            // sum all but last amount and add the balance to the last installment
            const sumOfInstallmentsExceptLast = cashInstallments.slice(0, cashInstallments.length - 1).reduce((a, b) => a + parseFloat(b.amount), 0);
            const lastInstallmentAmount = parseFloat(cashBalanceAmount) - parseFloat(sumOfInstallmentsExceptLast);

            const newCashInstallments = cashInstallments.map((item, i) => i === newCashNoOfInstallments - 1 ? { ...item, amount: lastInstallmentAmount } : item);
            setCashInstallments(newCashInstallments);
            setProjectUnit({ ...projectUnit, ProjectUnitInstallments: [...chequeInstallments, ...newCashInstallments] });
        }

        setCashRecalculateInstallments(false);
    }, [cashBalanceAmount, cashRecalculateInstallments]);

    const beforeUnloadHandler = (event) => {
        event.preventDefault();
        event.returnValue = true;
    };

    const onFieldChanged = (field, value) => {
        if (projectUnit) {
            setIsDirty(true);

            if (field === 'pricePerSqft' || field === 'dealAmount' || field === 'saleDeedAmount' || field === 'cashAmount' || field === 'chequeTokenAmount' || field === 'chequeMarginAmount' || field === 'cashTokenAmount' || field === 'cashMarginAmount' || field === 'balanceTillSaleDeed') {
                if (isNaN(value) || value === '' || parseInt(value) < 0) {
                    value = '0';
                } else {
                    value = parseFloat(value).toString();
                }
            }

            if (field === 'dealAmount') {
                setIsManualChange(true);
                setDealAmount(value);
                return;
            }

            if (field === 'cashAmount') {
                setIsManualChange(true);
                setCashAmount(value);
                return;
            }

            if (field === 'saleDeedAmount') {
                value = parseFloat(value).toFixed(0) || '0';
            }

            setIsManualChange(false);
            setProjectUnit({ ...projectUnit, [field]: value });
        }
    }

    const onAddProjectUnitCostAdditions = () => {
        if (!costAdditionName || !costAdditionAmount) {
            return setAlert({ message: 'Cost addition name and amount are required', isError: true });
        }

        if (projectUnit.ProjectUnitCostAdditions.find(x => x.name === costAdditionName)) {
            return setAlert({ message: 'Cost addition already exists', isError: true });
        }

        if (isNaN(costAdditionAmount)) {
            return setAlert({ message: 'Amount should be a number', isError: true });
        }

        setIsDirty(true);
        const newProjectUnitCostAdditions = [...projectUnit.ProjectUnitCostAdditions, { name: costAdditionName, amount: costAdditionAmount }];
        setCostAdditionName('');
        setCostAdditionAmount('');
        setProjectUnit({ ...projectUnit, ProjectUnitCostAdditions: newProjectUnitCostAdditions });
    }

    const onChangeProjectUnitCostAdditions = (name, field, value) => {
        setIsDirty(true);
        const updatedProjectUnitCostAdditions = projectUnit.ProjectUnitCostAdditions.map(x => x.name === name ? { ...x, [field]: value } : x);
        setProjectUnit({ ...projectUnit, ProjectUnitCostAdditions: updatedProjectUnitCostAdditions });
    }

    const onRemoveProjectUnitCostAdditions = (name) => {
        setIsDirty(true);
        const filteredProjectUnitCostAdditions = projectUnit.ProjectUnitCostAdditions.filter(item => item.name !== name);
        setProjectUnit({ ...projectUnit, ProjectUnitCostAdditions: filteredProjectUnitCostAdditions });
    }

    const onAddChequeInstallment = () => {
        setIsDirty(true);
        var newEndDate = moment();

        if (chequeInstallments.length > 0) {
            newEndDate = moment(chequeInstallments[chequeInstallments.length - 1].date);
        }
        if (chequeInstallments.length > 1) {
            const firstDate = moment(chequeInstallments[0].date);
            const daysDifference = newEndDate.diff(firstDate, 'days') / (chequeInstallments.length - 1) + 1;
            newEndDate = newEndDate.add(daysDifference, 'days');
        }

        const newChequeInstallments = [...chequeInstallments, { marginType: 'Cheque', date: newEndDate.format('YYYY-MM-DD'), amount: '0' }];
        setChequeInstallments(newChequeInstallments);
        setProjectUnit({ ...projectUnit, ProjectUnitInstallments: [...newChequeInstallments, ...cashInstallments] });
        setChequeRecalculateInstallments(true);
    }

    const onAddCashInstallment = () => {
        setIsDirty(true);
        var newEndDate = moment();

        if (cashInstallments.length > 0) {
            newEndDate = moment(cashInstallments[cashInstallments.length - 1].date);
        }
        if (cashInstallments.length > 1) {
            const firstDate = moment(cashInstallments[0].date);
            const daysDifference = newEndDate.diff(firstDate, 'days') / (cashInstallments.length - 1) + 1;
            newEndDate = newEndDate.add(daysDifference, 'days');
        }

        const newCashInstallments = [...cashInstallments, { marginType: 'Cash', date: newEndDate.format('YYYY-MM-DD'), amount: '0' }];
        setCashInstallments(newCashInstallments);
        setProjectUnit({ ...projectUnit, ProjectUnitInstallments: [...chequeInstallments, ...newCashInstallments] });
        setCashRecalculateInstallments(true);
    }

    const onDistributeChequeInstallmentDates = () => {
        setIsDirty(true);
        const newStartDate = chequeInstallments[0].date;
        const newEndDate = chequeInstallments[chequeInstallments.length - 1].date;

        const chequeDays = moment(newEndDate).diff(moment(newStartDate), 'days');

        const newChequeInstallments = [];
        for (let i = 0; i < chequeInstallments.length; i++) {
            newChequeInstallments.push({ ...chequeInstallments[i], date: moment(newStartDate).add(i * chequeDays / (parseInt(chequeInstallments.length) - 1), 'days').format('YYYY-MM-DD') });
        }
        setChequeInstallments(newChequeInstallments);
        setProjectUnit({ ...projectUnit, ProjectUnitInstallments: [...newChequeInstallments, ...cashInstallments] });
    }

    const onDistributeChequeInstallmentAmounts = () => {
        setIsDirty(true);
        const newChequeNoOfInstallments = chequeInstallments.length;
        const newInstallmentAmount = parseFloat(chequeBalanceAmount) / newChequeNoOfInstallments;
        const newChequeInstallments = [];
        for (let i = 0; i < newChequeNoOfInstallments; i++) {
            newChequeInstallments.push({ ...chequeInstallments[i], amount: newInstallmentAmount });
        }
        setChequeInstallments(newChequeInstallments);
        setProjectUnit({ ...projectUnit, ProjectUnitInstallments: [...newChequeInstallments, ...cashInstallments] });
    }

    const onDistributeCashInstallmentDates = () => {
        setIsDirty(true);
        const newStartDate = cashInstallments[0].date;
        const newEndDate = cashInstallments[cashInstallments.length - 1].date;

        const cashDays = moment(newEndDate).diff(moment(newStartDate), 'days');

        const newCashInstallments = [];
        for (let i = 0; i < cashInstallments.length; i++) {
            newCashInstallments.push({ ...cashInstallments[i], date: moment(newStartDate).add(i * cashDays / (parseInt(cashInstallments.length) - 1), 'days').format('YYYY-MM-DD') });
        }
        setCashInstallments(newCashInstallments);
        setProjectUnit({ ...projectUnit, ProjectUnitInstallments: [...chequeInstallments, ...newCashInstallments] });
    }

    const onDistributeCashInstallmentAmounts = () => {
        setIsDirty(true);
        const newCashNoOfInstallments = cashInstallments.length;
        const newInstallmentAmount = parseFloat(cashBalanceAmount) / newCashNoOfInstallments;
        const newCashInstallments = [];
        for (let i = 0; i < newCashNoOfInstallments; i++) {
            newCashInstallments.push({ ...cashInstallments[i], amount: newInstallmentAmount });
        }
        setCashInstallments(newCashInstallments);
        setProjectUnit({ ...projectUnit, ProjectUnitInstallments: [...chequeInstallments, ...newCashInstallments] });
    }

    const onChangeChequeInstallment = (index, field, value) => {
        if (field === 'amount') {
            if (isNaN(value) || value === '' || parseInt(value) < 0) {
                value = '0';
            } else {
                value = parseFloat(value).toString();
            }
        }

        setIsDirty(true);
        const updatedChequeInstallments = chequeInstallments.map((item, i) => i === index ? { ...item, [field]: value } : item);

        setChequeInstallments(updatedChequeInstallments);
        setProjectUnit({ ...projectUnit, ProjectUnitInstallments: [...updatedChequeInstallments, ...cashInstallments] });

        if (field === 'amount') {
            setChequeRecalculateInstallments(true);
            return;
        }
    }

    const onChangeCashInstallment = (index, field, value) => {
        if (field === 'amount') {
            if (isNaN(value) || value === '' || parseInt(value) < 0) {
                value = '0';
            } else {
                value = parseFloat(value).toString();
            }
        }

        setIsDirty(true);
        const updatedCashInstallments = cashInstallments.map((item, i) => i === index ? { ...item, [field]: value } : item);
        setCashInstallments(updatedCashInstallments);
        setProjectUnit({ ...projectUnit, ProjectUnitInstallments: [...chequeInstallments, ...updatedCashInstallments] });

        if (field === 'amount') {
            setCashRecalculateInstallments(true);
            return;
        }
    }

    const onRemoveChequeInstallment = () => {
        if (chequeBalanceAmount > 0 && chequeInstallments.length === 1) {
            return;
        }
        setIsDirty(true);
        const newChequeInstallments = chequeInstallments.slice(0, chequeInstallments.length - 1);
        setChequeInstallments(newChequeInstallments);
        setProjectUnit({ ...projectUnit, ProjectUnitInstallments: [...newChequeInstallments, ...cashInstallments] });
        setChequeRecalculateInstallments(true);
    }

    const onRemoveCashInstallment = () => {
        if (cashBalanceAmount > 0 && cashInstallments.length === 1) {
            return;
        }
        setIsDirty(true);
        const newCashInstallments = cashInstallments.slice(0, cashInstallments.length - 1);
        setCashInstallments(newCashInstallments);
        setProjectUnit({ ...projectUnit, ProjectUnitInstallments: [...chequeInstallments, ...newCashInstallments] });
        setCashRecalculateInstallments(true);
    }

    const onSave = () => {
        // validate
        if (!projectUnit.date) {
            setProjectUnit({ ...projectUnit, date: moment().format('YYYY-MM-DD') });
        }
        if (!projectUnit.brokerId || projectUnit.brokerId === '0') {
            return setAlert({ message: 'Broker is required', isError: true });
        }
        if (!projectUnit.pricePerSqft) {
            return setAlert({ message: 'Price / Sq. Ft. is required', isError: true });
        }
        if (!projectUnit.saleDeedAmount) {
            return setAlert({ message: 'Sale Deed Amount is required', isError: true });
        }
        if (!projectUnit.chequeTokenAmount) {
            return setAlert({ message: 'Cheque Token Amount is required', isError: true });
        }
        if (!projectUnit.chequeMarginAmount) {
            return setAlert({ message: 'Cheque Margin Amount is required', isError: true });
        }
        if (!projectUnit.cashTokenAmount) {
            return setAlert({ message: 'Cash Token Amount is required', isError: true });
        }
        if (!projectUnit.cashMarginAmount) {
            return setAlert({ message: 'Cash Margin Amount is required', isError: true });
        }
        if (!projectUnit.balanceTillSaleDeed) {
            return setAlert({ message: 'Balance Till Sale Deed is required', isError: true });
        }

        const errorElements = document.querySelectorAll('.form-input-error, .form-text-error');
        if (errorElements.length > 0) {
            return setAlert({ message: 'Please correct the errors marked in red', isError: true });
        }

        setSubmitting(true);
        ProjectUnit.addUpdate(projectUnit)
            .then(item => {
                if (mounted.current) {
                    if (item.error) {
                        return setAlert({ message: item.error, isError: true });
                    }

                    setId(item.id);
                    setIsDirty(false);
                    setProjectUnit(item);
                    setAlert({ message: `Saved`, isError: false });
                    setReload(true);
                }
            });
    }

    const toFloat = (value) => {
        var val = value;
        if (isNaN(val) || !isFinite(val) || val === '' || val === null || val === undefined || val === 'null' || val === 'undefined' || val === 'NaN' || val === 'Infinity' || val === '-Infinity' || val === '-NaN') {
            val = 0;
        }
        return parseFloat(val);
    }

    const toFixed = (value) => {
        return toFloat(value).toFixed(2);
    }

    const toINR = (value) => {
        var val = toFloat(value);
        return parseFloat(val).toLocaleString('en-IN', { style: 'currency', currency: 'INR' });
    }

    const onShowAdditionListClose = () => {
        if (costAdditionName && costAdditionAmount) {
            onAddProjectUnitCostAdditions();
        }
        setShowAdditionList(false);
    }

    return (
        <>
            <div className='non-printable'>
                <LoadingPage loading={loading} submitting={submitting}>
                    <SearchBar>
                        <Row style={{ display: 'flex', alignItems: 'center' }}>
                            <Col sm style={{ display: 'flex', alignItems: 'center', fontStyle: 'bold' }}>
                                <a href='/projects' style={{ paddingRight: '5px', paddingLeft: '5px' }}>Projects</a> /
                                <a href={`/projects/${projectId}/units`} style={{ paddingRight: '5px', paddingLeft: '5px' }}>{projectUnit?.Project?.name}</a> /
                                <a href={`/projects/${projectId}/units/${id}`} style={{ paddingRight: '5px', paddingLeft: '5px' }}>{projectUnit.name}</a> /
                                <span style={{ paddingRight: '5px', paddingLeft: '5px' }}>Soda Chitthi</span>
                            </Col>
                            <Col sm="auto"><Button variant="outline-primary" onClick={() => setPrintPreview(true)} title='Print'><Printer size={30} /></Button></Col>
                            <Col sm="auto"><Button variant="outline-danger" onClick={() => window.location.href = `/projects/${projectId}/units/${id}/payments`} title='Payments'><CurrencyRupee size={30} /></Button></Col>
                            <Col sm="auto"><Button variant="outline-dark" onClick={() => setShowCalculations(true)} title='Calculations'><Calculator size={30} /></Button></Col>
                            <Col sm="auto"><Button variant="outline-success" onClick={() => onSave()} title='Save' disabled={submitting}><Floppy size={30} /></Button></Col>
                        </Row>
                    </SearchBar>
                    <Content>
                        {projectUnit.Customers && projectUnit.Customers.length > 0 &&
                            <Row>
                                <Col lg>
                                    <FormInput value={projectUnit.Customers[0].name || ''} label='Name' disabled />
                                </Col>
                                <Col lg>
                                    <FormInput value={projectUnit.Customers[0].address || '-'} label='Address' disabled />
                                </Col>
                                <Col lg>
                                    <FormInput value={projectUnit.Customers[0].dateOfBirth && moment(projectUnit.Customers[0].dateOfBirth).format('DD-MM-YYYY') || '-'} label='DOB' disabled />
                                </Col>
                                <Col lg>
                                    <FormInput value={projectUnit.Customers[0].contactNumber || '-'} label='Contact Number' disabled />
                                </Col>
                            </Row>
                        }
                        <Row>
                            <Col lg>
                                <FormInput value={projectUnit.name || ''} label='Unit' disabled />
                            </Col>
                            <Col lg>
                                <FormInput value={projectUnit.type || ''} label='Type' disabled />
                            </Col>
                            <Col lg>
                                <FormInput value={projectUnit.superBuiltUpArea || ''} label='Super Built Up Area (SBA)' disabled />
                            </Col>
                            <Col lg>
                                <FormInput value={projectUnit.builtUpArea || ''} label='Built Up Area (BA)' disabled />
                            </Col>
                            <Col lg>
                                <FormInput value={projectUnit.carpetArea || ''} label='Carpet Area (CA)' disabled />
                            </Col>
                        </Row>
                        <Row style={{ borderBottomStyle: 'dashed', borderBottomColor: '#D0AE6B', borderBottomWidth: '2px', marginBottom: '20px' }}></Row>

                        <Row>
                            <Col lg>
                                <FormSelect value={projectUnit.brokerId || '0'} indexfield='id' label='Broker' field='name' options={brokers} onChange={(e) => onFieldChanged('brokerId', e.target.value)} />
                            </Col>
                            <Col lg={2}>
                                <FormInput type="date" value={moment(projectUnit.sodaChitthiDate).format('YYYY-MM-DD') || ''} label='Date' onChange={(e) => onFieldChanged('sodaChitthiDate', e.target.value)} />
                            </Col>
                        </Row>
                        <Row>
                            <Col lg>
                                <FormInput type='currency' value={projectUnit.pricePerSqft || ''} label='Price / Sq. Ft.' minvalue='0' onChange={(e) => onFieldChanged('pricePerSqft', e.target.value)} />
                            </Col>
                            <Col lg>
                                <FormSelect value={projectUnit.areaType} indexfield='key' label='Area Type' field='value' options={areaTypes} onChange={(e) => onFieldChanged('areaType', e.target.value)} />
                            </Col>
                            <Col lg>
                                <FormInput type='currency' value={dealAmount} label='Deal Amount' minvalue='0' onChange={(e) => onFieldChanged('dealAmount', e.target.value)} />
                            </Col>
                            <Col lg>
                                <FormInput type='currency' value={projectUnit.saleDeedAmount || ''} label='Sale Deed Amount' minvalue='0' maxvalue={dealAmount} onChange={(e) => onFieldChanged('saleDeedAmount', e.target.value)} />
                            </Col>
                            <Col lg>
                                <FormInput type='currency' value={cashAmount} label='Cash Amount' minvalue='0' maxvalue={dealAmount} onChange={(e) => onFieldChanged('cashAmount', e.target.value)} />
                            </Col>
                            <Col lg>
                                <InputGroup>
                                    <FormInput type='currency' value={totalCostAdditions} label='Additions' disabled />
                                    <Button variant="success" className="mb-3" onClick={() => setShowAdditionList(true)}><PlusLg /></Button>
                                </InputGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg>
                                <Table bordered hover className='soda-chitthi-table'>
                                    <thead>
                                        <tr>
                                            <th rowSpan={2} width='12%'></th>
                                            <th colSpan={3} width='36%' className='leftLine'>Cheque</th>
                                            <th colSpan={3} width='36%' className='leftLine'>Cash</th>
                                            <th colSpan={2} width='16%' className='leftLine'>Total</th>
                                        </tr>
                                        <tr>
                                            <th className='leftLine'>Amount</th>
                                            <th width='5%'>%</th>
                                            <th>On or Before</th>
                                            <th className='leftLine'>Amount</th>
                                            <th width='5%'>%</th>
                                            <th>On or Before</th>
                                            <th className='leftLine'>Amount</th>
                                            <th width='5%'>%</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>Token Amount</td>
                                            <td className='number leftLine'><FormInput type='currency' value={projectUnit.chequeTokenAmount || ''} minvalue='0' maxvalue={totalDeedAmount} onChange={(e) => onFieldChanged('chequeTokenAmount', e.target.value)} /></td>
                                            <td className='number'><FormText percentage>{chequeTokenAmountPercentage}</FormText></td>
                                            <td className='center'><FormInput type="date" value={moment(projectUnit.chequeTokenAmountEndDate).format('YYYY-MM-DD') || ''} onChange={(e) => onFieldChanged('chequeTokenAmountEndDate', e.target.value)} /></td>
                                            <td className='number leftLine'><FormInput type='currency' value={projectUnit.cashTokenAmount || ''} minvalue='0' maxvalue={cashAmount} onChange={(e) => onFieldChanged('cashTokenAmount', e.target.value)} /></td>
                                            <td className='number'><FormText percentage>{cashTokenAmountPercentage}</FormText></td>
                                            <td className='center'><FormInput type="date" value={moment(projectUnit.cashTokenAmountEndDate).format('YYYY-MM-DD') || ''} onChange={(e) => onFieldChanged('cashTokenAmountEndDate', e.target.value)} /></td>
                                            <th className='number leftLine'><FormText maxvalue={totalAmount}>{toINR(parseFloat(projectUnit.chequeTokenAmount) + parseFloat(projectUnit.cashTokenAmount))}</FormText></th>
                                            <th className='number'><FormText percentage>{toFixed((parseFloat(projectUnit.chequeTokenAmount) + parseFloat(projectUnit.cashTokenAmount)) / parseFloat(totalAmount) * 100.0)}</FormText></th>
                                        </tr>
                                        <tr>
                                            <td>Margin Money</td>
                                            <td className='number leftLine'><FormInput type='currency' value={projectUnit.chequeMarginAmount || ''} minvalue='0' maxvalue={totalDeedAmount} onChange={(e) => onFieldChanged('chequeMarginAmount', e.target.value)} /></td>
                                            <td className='number'><FormText percentage>{chequeMarginAmountPercentage}</FormText></td>
                                            <td className='center'><FormInput type="date" value={moment(projectUnit.chequeMarginAmountEndDate).format('YYYY-MM-DD') || ''} onChange={(e) => onFieldChanged('chequeMarginAmountEndDate', e.target.value)} /></td>
                                            <td className='number leftLine'><FormInput type='currency' value={projectUnit.cashMarginAmount || ''} minvalue='0' maxvalue={cashAmount} onChange={(e) => onFieldChanged('cashMarginAmount', e.target.value)} /></td>
                                            <td className='number'><FormText percentage>{cashMarginAmountPercentage}</FormText></td>
                                            <td className='center'><FormInput type="date" value={moment(projectUnit.cashMarginAmountEndDate).format('YYYY-MM-DD') || ''} onChange={(e) => onFieldChanged('cashMarginAmountEndDate', e.target.value)} /></td>
                                            <th className='number leftLine'><FormText maxvalue={totalAmount}>{toINR(parseFloat(projectUnit.chequeMarginAmount) + parseFloat(projectUnit.cashMarginAmount))}</FormText></th>
                                            <th className='number'><FormText percentage>{toFixed((parseFloat(projectUnit.chequeMarginAmount) + parseFloat(projectUnit.cashMarginAmount)) / parseFloat(totalAmount) * 100.0)}</FormText></th>
                                        </tr>
                                        <tr>
                                            <td>Till Sale Deed Reg</td>
                                            <td className='number leftLine'><FormInput type='currency' value={projectUnit.balanceTillSaleDeed || ''} minvalue='0' maxvalue={totalDeedAmount} onChange={(e) => onFieldChanged('balanceTillSaleDeed', e.target.value)} /></td>
                                            <td className='number'><FormText percentage>{balanceTillSaleDeedPercentage}</FormText></td>
                                            <td className='center'><FormInput type="date" value={moment(projectUnit.paymentTermsEndDate).format('YYYY-MM-DD') || ''} onChange={(e) => onFieldChanged('paymentTermsEndDate', e.target.value)} /></td>

                                            <td className='center leftLine' colSpan={3}>- NA -</td>
                                            <th className='number leftLine'><FormText maxvalue={totalAmount}>{toINR(parseFloat(projectUnit.balanceTillSaleDeed) + 0)}</FormText></th>
                                            <th className='number'>{toFixed(parseFloat(projectUnit.balanceTillSaleDeed) / parseFloat(totalAmount) * 100.0)}</th>
                                        </tr>
                                        <tr>
                                            <td>Balance Amount</td>
                                            <td className='center'><FormText minvalue='0' maxvalue={totalDeedAmount}>{toINR(parseFloat(chequeBalanceAmount))}</FormText></td>
                                            <td className='number'><FormText percentage>{chequeBalanceAmountPercentage}</FormText></td>
                                            <td></td>
                                            <td className='center'><FormText minvalue='0' maxvalue={cashAmount}>{toINR(parseFloat(cashBalanceAmount))}</FormText></td>
                                            <td className='number'><FormText percentage>{cashBalanceAmountPercentage}</FormText></td>
                                            <td></td>
                                            <th className='number leftLine'><FormText minvalue='0' maxvalue={totalAmount}>{toINR(parseFloat(chequeBalanceAmount) + parseFloat(cashBalanceAmount))}</FormText></th>
                                            <th className='number'><FormText percentage>{toFixed((parseFloat(chequeBalanceAmount) + parseFloat(cashBalanceAmount)) / parseFloat(totalAmount) * 100.0)}</FormText></th>
                                        </tr>

                                        <tr>
                                            <th>Total</th>
                                            <th className='center leftLine' colSpan={3}><FormText equalto={totalDeedAmount}>{toINR(parseFloat(projectUnit.chequeTokenAmount) + parseFloat(projectUnit.chequeMarginAmount) + parseFloat(projectUnit.balanceTillSaleDeed) + parseFloat(chequeBalanceAmount))}</FormText></th>
                                            <th className='center leftLine' colSpan={3}><FormText equalto={cashAmount}>{toINR(parseFloat(projectUnit.cashTokenAmount) + parseFloat(projectUnit.cashMarginAmount) + parseFloat(cashBalanceAmount))}</FormText></th>
                                            <th className='number leftLine'><FormText equalto={totalAmount}>{toINR(parseFloat(projectUnit.chequeTokenAmount) + parseFloat(projectUnit.chequeMarginAmount) + parseFloat(projectUnit.balanceTillSaleDeed) + parseFloat(chequeBalanceAmount) + parseFloat(projectUnit.cashTokenAmount) + parseFloat(projectUnit.cashMarginAmount) + parseFloat(cashBalanceAmount))}</FormText></th>
                                            <th className='number'><FormText equalto='100'>{toFixed((parseFloat(projectUnit.chequeTokenAmount) + parseFloat(projectUnit.chequeMarginAmount) + parseFloat(projectUnit.balanceTillSaleDeed) + parseFloat(chequeBalanceAmount) + parseFloat(projectUnit.cashTokenAmount) + parseFloat(projectUnit.cashMarginAmount) + parseFloat(cashBalanceAmount)) / parseFloat(totalAmount) * 100.0)}</FormText></th>
                                        </tr>

                                        <tr>
                                            <td style={{ verticalAlign: 'top', paddingTop: '20px' }}>Installments for Balance Amount</td>
                                            <td className='leftLine' colSpan={3} style={{ verticalAlign: 'top' }}>

                                                <Table bordered hover className='soda-chitthi-table' style={{ width: '100%' }}>
                                                    <thead>
                                                        <tr>
                                                            <th>#</th>
                                                            <th>Date</th>
                                                            <th width='2%'><FormButton variant="outline-primary" onClick={() => onDistributeChequeInstallmentDates()} title='Distribute dates equally'><DistributeVertical size={25} /></FormButton></th>
                                                            <th>Amount</th>
                                                            <th width='2%'><FormButton variant="outline-primary" onClick={() => onDistributeChequeInstallmentAmounts()} title='Distribute amount equally'><DistributeVertical size={25} /></FormButton></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {chequeInstallments && chequeInstallments.map((item, index) =>
                                                            <tr key={index}>
                                                                <td className='center'>{index + 1}</td>
                                                                <td className='center' colSpan={2}>
                                                                    <FormInput type='date' value={moment(item.date).format('YYYY-MM-DD')} onChange={(e) => onChangeChequeInstallment(index, 'date', e.target.value)} />
                                                                </td>
                                                                <td className='center' colSpan={2}>
                                                                    <FormInput type='currency' value={item.amount} minvalue='0' maxvalue={chequeBalanceAmount} onChange={(e) => onChangeChequeInstallment(index, 'amount', e.target.value)} disabled={chequeInstallments.length - 1 === index} />
                                                                </td>
                                                            </tr>
                                                        )}
                                                        <tr>
                                                            <th colSpan={5}>
                                                                <Row>
                                                                    <Col lg style={{ textAlign: 'center' }}>
                                                                        <FormButton variant="outline-danger" onClick={() => onRemoveChequeInstallment()}>Remove last installment</FormButton>
                                                                    </Col>
                                                                    <Col lg style={{ textAlign: 'center' }}>
                                                                        <FormButton variant="outline-success" onClick={() => onAddChequeInstallment()}>Add new installment</FormButton>
                                                                    </Col>
                                                                </Row>
                                                            </th>
                                                        </tr>
                                                        <tr>
                                                            <th colSpan={3}>Total</th>
                                                            <th colSpan={2} className='center'><FormText equalto={chequeBalanceAmount}>{toINR(chequeInstallments.reduce((a, b) => a + parseFloat(b.amount), 0))}</FormText></th>
                                                        </tr>
                                                    </tbody>
                                                </Table>
                                            </td>
                                            <td className='leftLine' colSpan={3} style={{ verticalAlign: 'top' }}>
                                                <Table bordered hover className='soda-chitthi-table' style={{ width: '100%' }}>
                                                    <thead>
                                                        <tr>
                                                            <th>#</th>
                                                            <th>Date</th>
                                                            <th width='2%'><FormButton variant="outline-primary" onClick={() => onDistributeCashInstallmentDates()} title='Distribute dates equally'><DistributeVertical size={25} /></FormButton></th>
                                                            <th>Amount</th>
                                                            <th width='2%'><FormButton variant="outline-primary" onClick={() => onDistributeCashInstallmentAmounts()} title='Distribute amount equally'><DistributeVertical size={25} /></FormButton></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {cashInstallments && cashInstallments.map((item, index) =>
                                                            <tr key={index}>
                                                                <td className='center'>{index + 1}</td>
                                                                <td className='center' colSpan={2}>
                                                                    <FormInput type='date' value={moment(item.date).format('YYYY-MM-DD')} onChange={(e) => onChangeCashInstallment(index, 'date', e.target.value)} />
                                                                </td>
                                                                <td className='center' colSpan={2}>
                                                                    <FormInput type='currency' value={item.amount} minvalue='0' maxvalue={cashBalanceAmount} onChange={(e) => onChangeCashInstallment(index, 'amount', e.target.value)} disabled={cashInstallments.length - 1 === index} />
                                                                </td>
                                                            </tr>
                                                        )}
                                                        <tr>
                                                            <th colSpan={5}>
                                                                <Row>
                                                                    <Col lg style={{ textAlign: 'center' }}>
                                                                        <FormButton variant="outline-danger" onClick={() => onRemoveCashInstallment()}>Remove last installment</FormButton>
                                                                    </Col>
                                                                    <Col lg style={{ textAlign: 'center' }}>
                                                                        <FormButton variant="outline-success" onClick={() => onAddCashInstallment()}>Add new installment</FormButton>
                                                                    </Col>
                                                                </Row>
                                                            </th>
                                                        </tr>
                                                        <tr>
                                                            <th colSpan={3}>Total</th>
                                                            <th colSpan={2} className='center'><FormText equalto={cashBalanceAmount}>{toINR(cashInstallments.reduce((a, b) => a + parseFloat(b.amount), 0))}</FormText></th>
                                                        </tr>
                                                    </tbody>
                                                </Table>
                                            </td>
                                            <td className='leftLine' colSpan={2}></td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </Col>
                        </Row>
                    </Content>

                    {alert &&
                        <AlertBox isError={alert.isError} onClose={() => setAlert(null)}>
                            {alert.message}
                        </AlertBox>}

                    {showAdditionList &&
                        <AlertBox title={`Cost Additions: ${toINR(totalCostAdditions)}`} isError={false} onClose={() => onShowAdditionListClose()}>
                            <div>
                                <Row>
                                    <Col lg>
                                        <FormInput type='number' value={projectUnit.gstPercentage || ''} label='GST %' onChange={(e) => onFieldChanged('gstPercentage', e.target.value)} />
                                    </Col>
                                    <Col lg>
                                        <FormInput type='currency' value={gstAmount} label='Amount' disabled />
                                    </Col>
                                    <Col lg={2}></Col>
                                </Row>
                                <Row>
                                    <Col lg>
                                        <FormInput type='number' value={projectUnit.stampDutyPercentage || ''} label='Stamp Duty %' onChange={(e) => onFieldChanged('stampDutyPercentage', e.target.value)} />
                                    </Col>
                                    <Col lg>
                                        <FormInput type='currency' value={stampDutyAmount} label='Amount' disabled />
                                    </Col>
                                    <Col lg={2}></Col>
                                </Row>

                                {projectUnit && projectUnit.ProjectUnitCostAdditions && projectUnit.ProjectUnitCostAdditions.map((item, index) =>
                                    <Row key={index}>
                                        <Col lg>
                                            <FormInput value={item.name} label='Add' onChange={(e) => onChangeProjectUnitCostAdditions(item.name, 'name', e.target.value)} />
                                        </Col>
                                        <Col lg>
                                            <FormInput type='currency' value={item.amount} label='Amount' onChange={(e) => onChangeProjectUnitCostAdditions(item.name, 'amount', e.target.value)} />
                                        </Col>
                                        <Col lg='2' style={{ paddingTop: '10px' }}>
                                            <FormButton variant="outline-danger" onClick={() => onRemoveProjectUnitCostAdditions(item.name)}><Trash /></FormButton>
                                        </Col>
                                    </Row>
                                )}

                                <Row>
                                    <Col lg>
                                        <FormInput value={costAdditionName} label='Add' onChange={(e) => setCostAdditionName(e.target.value)} />
                                    </Col>
                                    <Col lg>
                                        <FormInput type='currency' value={costAdditionAmount} label='Amount' onChange={(e) => setCostAdditionAmount(e.target.value)} />
                                    </Col>
                                    <Col lg='2' style={{ paddingTop: '10px' }}>
                                        <Button variant="outline-primary" onClick={() => onAddProjectUnitCostAdditions()}><PlusLg /></Button>
                                    </Col>
                                </Row>
                            </div>
                        </AlertBox>}

                    <Offcanvas placement='end' show={showCalculations} onHide={() => setShowCalculations(false)}>
                        <Offcanvas.Header closeButton>
                            <Offcanvas.Title>Soda Chitthi</Offcanvas.Title>
                        </Offcanvas.Header>
                        <Offcanvas.Body>
                            <Table hover className='soda-chitthi-calculations-table'>
                                <thead>
                                    <tr>
                                        <th colSpan={3}>Calculations</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr className='topLine'>
                                        <td>Super Built Up Area</td>
                                        <td className='operation'></td>
                                        <td className='number'>{toINR(projectUnit.superBuiltUpArea)}</td>
                                    </tr>
                                    <tr>
                                        <td>Price / Sq. Ft.</td>
                                        <td className='operation'>x</td>
                                        <td className='number'>{toINR(projectUnit.pricePerSqft)}</td>
                                    </tr>
                                    <tr className='topLine'>
                                        <td>Deal Amount</td>
                                        <td></td>
                                        <th className='number'>{toINR(dealAmount)}</th>
                                    </tr>
                                    <tr>
                                        <td>Cash Amount</td>
                                        <td className='operation'>-</td>
                                        <td className='number'>{toINR(cashAmount)}</td>
                                    </tr>
                                    <tr className='topLine'>
                                        <td>Sale Deed Amount</td>
                                        <td className='operation'></td>
                                        <th className='number'>{toINR(projectUnit.saleDeedAmount)}</th>
                                    </tr>
                                    <tr>
                                        <td>GST [{projectUnit.gstPercentage}%]</td>
                                        <td className='operation'>+</td>
                                        <td className='number'>{toINR(gstAmount)}</td>
                                    </tr>
                                    <tr>
                                        <td>Stamp Duty [{projectUnit.stampDutyPercentage}%]</td>
                                        <td className='operation'>+</td>
                                        <td className='number'>{toINR(stampDutyAmount)}</td>
                                    </tr>
                                    {projectUnit && projectUnit.ProjectUnitCostAdditions && projectUnit.ProjectUnitCostAdditions.map((item, index) =>
                                        <tr key={index}>
                                            <td>{item.name}</td>
                                            <td className='operation'>{parseFloat(item.amount) >= 0 ? '+' : '-'}</td>
                                            <td className='number'>{toINR(Math.abs(item.amount))}</td>
                                        </tr>
                                    )}
                                    <tr className='topLine'>
                                        <td>Cheque Amount</td>
                                        <td></td>
                                        <th className='number'>{toINR(totalDeedAmount)}</th>
                                    </tr>
                                    <tr>
                                        <td>Cash Amount</td>
                                        <td className='operation'>+</td>
                                        <td className='number'>{toINR(cashAmount)}</td>
                                    </tr>
                                    <tr className='topLine' style={{ borderTopStyle: 'double' }}>
                                        <td style={{ fontWeight: 'bold' }}>Total Deal Amount</td>
                                        <td></td>
                                        <th className='number'>{toINR(totalAmount)}</th>
                                    </tr>
                                    <tr>
                                        <td>Super Built Up Area</td>
                                        <td className='operation'>÷</td>
                                        <td className='number'>{toINR(projectUnit.superBuiltUpArea)}</td>
                                    </tr>
                                    <tr className='topLine'>
                                        <td>Effective Price/Sq.Ft.</td>
                                        <td></td>
                                        <th className='number'>{toINR(effectivePricePerSqft)}</th>
                                    </tr>
                                </tbody>
                            </Table>
                        </Offcanvas.Body>
                    </Offcanvas>

                    {/* <div style={{textAlign: 'left'}}><pre>
                        {JSON.stringify(project, null, 2)}
                    </pre></div> */}
                </LoadingPage>
            </div>

            {/* Screen */}
            <div className='printable'>
                {/* Page 1 */}
                <div className='a4'>
                    {/* Header */}
                    <div style={{ width: '100%', height: '70px', backgroundColor: 'lightgrey', textAlign: 'center', lineHeight: '50px' }}>
                        <h1>Soda Chitthi</h1>
                    </div>
                    {/* Content */}
                    <div style={{ width: '100%', height: 'calc(100% - 50px)' }}>
                        {projectUnit.Customers && projectUnit.Customers.length > 0 &&
                            <Table bordered>
                                <tbody>
                                    <tr>
                                        <th width='20%'>Date</th>
                                        <td colSpan={5}>{moment(projectUnit.sodaChitthiDate).format('DD-MM-YYYY')}</td>
                                    </tr>
                                    <tr>
                                        <th>Name</th>
                                        <td colSpan={5}>{projectUnit.Customers[0].name}</td>
                                    </tr>
                                    <tr>
                                        <th>Contact Details</th>
                                        <td>{projectUnit.Customers[0].address}</td>
                                        <th>DOB</th>
                                        <td>{moment(projectUnit.Customers[0].dateOfBirth).format('DD-MM-YYYY')}</td>
                                        <th>Number</th>
                                        <td>{projectUnit.Customers[0].contactNumber}</td>
                                    </tr>
                                </tbody>
                            </Table>
                        }
                        <br />
                        <Table bordered>
                            <tbody>
                                <tr>
                                    <th width='20%'>Broker</th>
                                    <td colSpan={5}>{projectUnit.Broker?.name}</td>
                                </tr>
                            </tbody>
                        </Table>
                        <br />
                        <Table bordered>
                            <tbody>
                                <tr>
                                    <th colSpan={9} className='center'>Unit Details</th>
                                </tr>
                                <tr>
                                    <th width='20%'>Unit No.</th>
                                    <td colSpan={2}>{projectUnit.name}</td>
                                    <th colSpan={6} className='center'>Area</th>
                                </tr>
                                <tr>
                                    <th>Type of Unit</th>
                                    <td colSpan={2}>{projectUnit.type}</td>
                                    <th width='10%' className='center'>SBA</th>
                                    <td>{projectUnit.superBuiltUpArea}</td>
                                    <th width='10%' className='center'>BA</th>
                                    <td>{projectUnit.builtUpArea}</td>
                                    <th width='10%' className='center'>CA</th>
                                    <td>{projectUnit.carpetArea}</td>
                                </tr>
                            </tbody>
                        </Table>
                        <br />
                        <Table bordered>
                            <tbody>
                                <tr>
                                    <th width='35%'>Deal Amount</th>
                                    <td width='20%' className='right'>{toINR(dealAmount)}</td>
                                    <th>Price/Sq.Ft. ({projectUnit.areaType})</th>
                                    <td>{toINR(projectUnit.pricePerSqft)}</td>
                                    <th>Net Price</th>
                                </tr>
                                <tr>
                                    <th>Add: GST</th>
                                    <td className='right'>{toINR(gstAmount)}</td>
                                    <td colSpan={3}>{projectUnit.gstPercentage} %</td>
                                </tr>
                                <tr>
                                    <th>Add: Stamp Duty</th>
                                    <td className='right'>{toINR(stampDutyAmount)}</td>
                                    <td colSpan={3}>{projectUnit.stampDutyPercentage} %</td>
                                </tr>
                                {projectUnit && projectUnit.ProjectUnitCostAdditions && projectUnit.ProjectUnitCostAdditions.map((item, index) =>
                                    <tr key={index}>
                                        <th>Add: {item.name}</th>
                                        <td className='right'>{toINR(item.amount)}</td>
                                        <td colSpan={3}></td>
                                    </tr>
                                )}
                                <tr>
                                    <th>Total</th>
                                    <th className='right'>{toINR(totalAmount)}</th>
                                    <th>Price/Sq.Ft. ({projectUnit.areaType})</th>
                                    <td>{toINR(effectivePricePerSqft)}</td>
                                    <th>Gross Price</th>
                                </tr>
                            </tbody>
                        </Table>
                        <br />
                        <Table bordered>
                            <tbody>
                                <tr>
                                    <th width='35%'>Sale Deed Amount (Cheque)</th>
                                    <td width='20%' className='right'>{toINR(totalDeedAmount)}</td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <th>Cash Amount</th>
                                    <td className='right'>{toINR(cashAmount)}</td>
                                    <td></td>
                                </tr>
                            </tbody>
                        </Table>
                    </div>
                </div>
                {/* Page 2 */}
                <div className='a4 pagebreak'>
                    {/* Header */}
                    <div style={{ width: '100%', height: '70px', backgroundColor: 'lightgrey', textAlign: 'center', lineHeight: '50px' }}>
                        <h1>Soda Chitthi - Payment Terms</h1>
                    </div>
                    {/* Content */}
                    <div style={{ width: '100%', height: 'calc(100% - 50px)' }}>
                        <Table bordered>
                            <tbody>
                                <tr>
                                    <th width='20%'></th>
                                    <td></td>
                                    <th width='38%' colSpan={3} className='center'>Cheque</th>
                                    <td></td>
                                    <th width='38%' colSpan={3} className='center'>Cash</th>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td></td>
                                    <th className='center'>Amount</th>
                                    <th className='center'>%</th>
                                    <th className='center'>Due</th>
                                    <td></td>
                                    <th className='center'>Amount</th>
                                    <th className='center'>%</th>
                                    <th className='center'>Due</th>
                                </tr>
                                <tr>
                                    <th>Token Amount</th>
                                    <td></td>
                                    <td>{toINR(projectUnit.chequeTokenAmount)}</td>
                                    <td>{chequeTokenAmountPercentage}</td>
                                    <td>{moment(projectUnit.chequeTokenAmountEndDate).format('DD-MM-YYYY')}</td>
                                    <td></td>
                                    <td>{toINR(projectUnit.cashTokenAmount)}</td>
                                    <td>{cashTokenAmountPercentage}</td>
                                    <td>{moment(projectUnit.cashTokenAmountEndDate).format('DD-MM-YYYY')}</td>
                                </tr>
                                <tr>
                                    <th>Margin Money</th>
                                    <td></td>
                                    <td>{toINR(projectUnit.chequeMarginAmount)}</td>
                                    <td>{chequeMarginAmountPercentage}</td>
                                    <td>{moment(projectUnit.chequeMarginAmountEndDate).format('DD-MM-YYYY')}</td>
                                    <td></td>
                                    <td>{toINR(projectUnit.cashMarginAmount)}</td>
                                    <td>{cashMarginAmountPercentage}</td>
                                    <td>{moment(projectUnit.cashMarginAmountEndDate).format('DD-MM-YYYY')}</td>
                                </tr>
                                <tr>
                                    <th>Till Sale Deed Reg</th>
                                    <td></td>
                                    <td>{toINR(projectUnit.balanceTillSaleDeed)}</td>
                                    <td>{balanceTillSaleDeedPercentage}</td>
                                    <td>{moment(projectUnit.paymentTermsEndDate).format('DD-MM-YYYY')}</td>
                                    <td></td>
                                    <td colSpan={3}></td>
                                </tr>
                                <tr>
                                    <th>Balance Amount</th>
                                    <td></td>
                                    <td>{toINR(chequeBalanceAmount)}</td>
                                    <td>{chequeBalanceAmountPercentage}</td>
                                    <td></td>
                                    <td></td>
                                    <td>{toINR(cashBalanceAmount)}</td>
                                    <td>{cashBalanceAmountPercentage}</td>
                                    <td></td>
                                </tr>
                            </tbody>
                        </Table>
                        <Table bordered>
                            <tbody>
                                <tr>
                                    <th colSpan={9} className='center'>Installments for Balance Amount</th>
                                </tr>
                                <tr>
                                    <th width='50%' className='center'>Cheque</th>
                                    <th className='center'>Cash</th>
                                </tr>
                                <tr>
                                    <td>
                                        <Table bordered>
                                            <tbody>
                                                {chequeInstallments && chequeInstallments.map((item, index) =>
                                                    <tr key={index}>
                                                        <td className='center'>{index + 1}</td>
                                                        <td className='center'>{moment(item.date).format('DD-MM-YYYY')}</td>
                                                        <td className='center'>{toINR(item.amount)}</td>
                                                    </tr>
                                                )}
                                                <tr>
                                                    <th colSpan={2} className='center'>Total</th>
                                                    <td className='center'>{toINR(chequeInstallments.reduce((a, b) => a + parseFloat(b.amount), 0))}</td>
                                                </tr>
                                            </tbody>
                                        </Table>
                                    </td>
                                    <td>
                                        <Table bordered>
                                            <tbody>
                                                {cashInstallments && cashInstallments.map((item, index) =>
                                                    <tr key={index}>
                                                        <td className='center'>{index + 1}</td>
                                                        <td className='center'>{moment(item.date).format('DD-MM-YYYY')}</td>
                                                        <td className='center'>{toINR(item.amount)}</td>
                                                    </tr>
                                                )}
                                                <tr>
                                                    <th colSpan={2} className='center'>Total</th>
                                                    <td className='center'>{toINR(cashInstallments.reduce((a, b) => a + parseFloat(b.amount), 0))}</td>
                                                </tr>
                                            </tbody>
                                        </Table>
                                    </td>
                                </tr>
                            </tbody>
                        </Table>
                    </div>
                </div>
            </div>
        </>
    )
}