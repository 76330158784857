class Comment {
  async get(modelId, filter) {
    const data = await fetch(`${process.env.REACT_APP_API_URL}/comments/${modelId}?q=${filter}`, {
      headers: {
        'x-access-token': localStorage.getItem('token')
      }
    });
    return await data.json();
  }

  async add(modelId, record) {
    const data = await fetch(`${process.env.REACT_APP_API_URL}/comments/${modelId}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': localStorage.getItem('token')
      },
      body: JSON.stringify(record)
    });
    return await data.json();
  }

  async update(record) {
    const data = await fetch(`${process.env.REACT_APP_API_URL}/comments/${record.id}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': localStorage.getItem('token')
      },
      body: JSON.stringify(record)
    });
    return await data.json();
  }

  async delete(record) {
    const data = await fetch(`${process.env.REACT_APP_API_URL}/comments/${record.id}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': localStorage.getItem('token')
      }
    });
    return await data.json();
  }
}

module.exports = new Comment();