import React, { useEffect, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { FileEarmarkPlus } from 'react-bootstrap-icons';
import moment from 'moment';
import Global from '../../services/global';
import Project from '../../services/project';
import ProjectUnit from '../../services/projectUnit';
import Customer from '../../services/customer';
import InputGroup from 'react-bootstrap/InputGroup';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Template from '../../services/template';
import FormInput from '../../components/FormInput';
import SearchBar from '../SearchBar';
import Content from '../Content';
import AlertBox from '../AlertBox';
import LoadingPage from '../LoadingPage';
import JsonTree from '../JsonTree';

export default function PageFileFromTemplate() {
    const [searchParams, setSearchParams] = useSearchParams();
    const [template, setTemplate] = useState(searchParams.get('template') || '');
    const [modelId, setModelId] = useState(searchParams.get('modelId') || '');
    const [modelType, setModelType] = useState(searchParams.get('modelType') || '');
    const [model, setModel] = useState({});
    const [modelUsableFields, setModelUsableFields] = useState({});
    const [extraFields, setExtraFields] = useState({});
    const [isDirty, setIsDirty] = useState(false);
    const [reload, setReload] = useState(true);
    const [alert, setAlert] = useState(null);
    const [tags, setTags] = useState({});
    const [newFileName, setNewFileName] = useState('');

    const [submitting, setSubmitting] = useState(false);

    const [loading, setLoading] = useState(true);
    const [tagsLoading, setTagsLoading] = useState(true);

    useEffect(() => {
        setLoading(tagsLoading)
    }, [tagsLoading]);

    useEffect(() => {
        setSubmitting(false);
    }, [alert]);

    const mounted = useRef(true);

    useEffect(() => { document.title = "Create from template"; }, []);

    useEffect(() => {
        switch (modelType) {
            case 'project':
                Project.getById(modelId).then(item => setModel(item));
                break;
            case 'projectUnit':
                ProjectUnit.getById(modelId).then(item => setModel(item));
                break;
            case 'customer':
                Customer.getById(modelId).then(item => setModel(item));
                break;
            default:
                break;
        }

        setExtraFields({
            
            // MORE FROM SETTINGS
        });

        Global.get().then(items => {
            const extra = {};
            extra.today = moment(new Date()).format('DD-MMM-YYYY');
            extra.day = moment(new Date()).format('dddd');
            extra.now = moment(new Date()).format('HH:mm');

            items.forEach(item => {
                extra[item.key] = item.value;
            });

            setExtraFields(extra);
        });
    }, []);

    useEffect(() => {
        mounted.current = true;
        if (!reload) {
            return;
        }

        Template.getTags(template)
            .then(tags => {                
                Template.getLastUsedValues(modelId)
                    .then(item => {
                        if (mounted.current) {
                            if (item && item.tags) {                                
                                for (const key in tags) {
                                    tags[key] = item.tags[key];
                                }
                            }
                            
                            setTags(tags);
                            setTagsLoading(false);
                            setReload(false);
                            setSubmitting(false);
                        }
                    })
                    .catch(err => {
                        setTags(tags);
                        setTagsLoading(false);
                        setReload(false);
                        setSubmitting(false);
                    });
            })
            .catch(e => {
                console.log(e);
                //window.location.href = "/notfound";
            });

        return () => mounted.current = false;
    }, [reload]);

    useEffect(() => {
        if (isDirty) {
            setAlert(null);
            window.addEventListener("beforeunload", beforeUnloadHandler);
        }
        return () => {
            window.removeEventListener("beforeunload", beforeUnloadHandler);
        }
    }, [isDirty]);

    const skipKeys = ['id', 'projectId', 'customerId', 'updatedBy', 'createdAt', 'updatedAt', 'orderNumber', 'ProjectUnitCustomer', 'brokerId'];
    // const flattenJSON = (obj = {}, res = {}, extraKey = '') => {
    //     for (const key in obj) {
    //         if (skipKeys.includes(key)) {
    //             continue;
    //         }
    //         if (typeof obj[key] !== 'object') {                
    //             res[extraKey + key] = obj[key];
    //         } else {
    //             flattenJSON(obj[key], res, `${extraKey}${key}.`);
    //         };
    //     };
    //     return res;
    // };

    const beforeUnloadHandler = (event) => {
        event.preventDefault();
        event.returnValue = true;
    };

    const onFieldChanged = (field, value) => {
        if (tags) {
            setIsDirty(true);
            setTags({ ...tags, [field]: value });
        }
    }

    const onSave = () => {
        setSubmitting(true);
        const templateValues = {
            modelId: modelId,
            name: newFileName,
            tags: tags
        };

        Template.saveNewFileAndValues(template, templateValues)
            .then(item => {
                if (mounted.current) {
                    if (item.error) {
                        return setAlert({ message: item.error, isError: true });
                    }

                    setAlert({ message: `Saved`, isError: false });
                    setIsDirty(false);
                }
            });
    }

    const handleDragStart = (event, text) => {
        event.dataTransfer.setData('text/plain', text);
    };

    const handleDragOver = (event) => {
        // event.preventDefault();
    };

    const handleDrop = (event, field) => {
        // event.preventDefault();
        // const text = event.dataTransfer.getData('text/plain');
        // setIsDirty(true);
        // setTags({ ...tags, [field]: text });
    };

    return (
        <LoadingPage loading={loading} submitting={submitting}>
            <SearchBar>
                <Row style={{ display: 'flex', alignItems: 'center' }}>
                    <Col sm style={{ display: 'flex', alignItems: 'center', fontStyle: 'bold' }}>
                        {modelType === 'projectUnit' && model.id &&
                            <>
                                <a href='/projects' style={{ paddingRight: '5px', paddingLeft: '5px' }}>Projects</a> /
                                <a href={`/projects/${model.projectId}/units`} style={{ paddingRight: '5px', paddingLeft: '5px' }}>{model.Project.name}</a> /
                                <a href={`/projects/${model.projectId}/units/${model.id}`} style={{ paddingRight: '5px', paddingLeft: '5px' }}>{model.name}</a> /
                                <span style={{ paddingRight: '5px', paddingLeft: '5px' }}>{template}</span>
                            </>
                        }
                        {modelType === 'project' && model.id &&
                            <>
                                <a href='/projects' style={{ paddingRight: '5px', paddingLeft: '5px' }}>Projects</a> /
                                <a href={`/projects/${model.id}`} style={{ paddingRight: '5px', paddingLeft: '5px' }}>{model.name}</a> /
                                <span style={{ paddingRight: '5px', paddingLeft: '5px' }}>{template}</span>
                            </>
                        }
                        {modelType === 'customer' && model.id &&
                            <>
                                <a href='/customers' style={{ paddingRight: '5px', paddingLeft: '5px' }}>Customers</a> /
                                <a href={`/customers/${model.id}`} style={{ paddingRight: '5px', paddingLeft: '5px' }}>{model.name}</a> /
                                <span style={{ paddingRight: '5px', paddingLeft: '5px' }}>{template}</span>
                            </>
                        }
                    </Col>
                    <Col sm="auto"><Button variant="outline-success" onClick={() => onSave()} title='Save' disabled={submitting}><FileEarmarkPlus size={30} /></Button></Col>
                </Row>
            </SearchBar>
            <Content>
                <Row>
                    <Col>
                        <FormInput value={newFileName} label='File name' onChange={(e) => setNewFileName(e.target.value)} />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Card className='pt-2' style={{ textAlign: 'center', backgroundColor: '#d0ae6b', borderColor: '#d0ae6b', borderStyle: 'solid', borderWidth: '1px' }}>
                            <Card.Title style={{ color: 'white' }}>File tags</Card.Title>
                            <Card.Body style={{ maxHeight: '600px', overflowY: 'auto' }}>
                                {Object.keys(tags).map((tag, i) =>
                                    <FormInput key={i} type='search' value={tags[tag] || ''} label={tag} onChange={(e) => onFieldChanged(tag, e.target.value)} onDragOver={handleDragOver} onDrop={(e) => handleDrop(e, tag)} />
                                )}
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col>
                        <Card className='pt-2' style={{ textAlign: 'center', backgroundColor: 'black', borderColor: '#d0ae6b', borderStyle: 'solid', borderWidth: '1px' }}>
                            <Card.Title style={{ color: 'white' }}>Selectable values (DRAGGABLE)</Card.Title>
                            <Card.Body style={{ maxHeight: '600px', overflowY: 'auto' }}>
                                {/* {Object.keys(modelUsableFields).map((tag, i) =>
                                    <>
                                        {(tag.split('.').length > Object.keys(modelUsableFields)[i - 1]?.split('.').length || tag.split('.').length < Object.keys(modelUsableFields)[i - 1]?.split('.').length ||
                                            tag.split('.')[2] !== Object.keys(modelUsableFields)[i - 1]?.split('.')[2]) &&
                                            <div className="mb-3 mt-3" style={{ borderBottomColor: '#d0ae6b', borderBottomWidth: '3px', borderBottomStyle: 'dotted' }}></div>
                                        }
                                        <InputGroup key={i} className="mb-2" draggable="true" style={{ cursor: 'move' }} onDragStart={(e) => handleDragStart(e, modelUsableFields[tag] || '')}>
                                            <InputGroup.Text>{tag}</InputGroup.Text>
                                            <Form.Control style={{ cursor: 'move' }} value={modelUsableFields[tag] || ''} disabled={true} />
                                        </InputGroup>
                                    </>
                                )} */}
                                {model && <JsonTree field={modelType} value={model} skipKeys={skipKeys} />}
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col>
                        <Card className='pt-2' style={{ textAlign: 'center', backgroundColor: 'black', borderColor: '#d0ae6b', borderStyle: 'solid', borderWidth: '1px' }}>
                            <Card.Title style={{ color: 'white' }}>Global values (DRAGGABLE)</Card.Title>
                            <Card.Body style={{ maxHeight: '600px', overflowY: 'auto' }}>
                                {Object.keys(extraFields).map((tag, i) =>
                                    <InputGroup key={i} className="mb-2" draggable="true" style={{ cursor: 'move' }} onDragStart={(e) => handleDragStart(e, extraFields[tag] || '')}>
                                        <InputGroup.Text>{tag}</InputGroup.Text>
                                        <Form.Control style={{ cursor: 'move' }} value={extraFields[tag] || ''} disabled={true} />
                                    </InputGroup>
                                )}
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>

                {/* <div style={{ textAlign: 'left', backgroundColor: 'white' }}><pre>{JSON.stringify(tags, null, 2)}</pre></div>
                <div style={{ textAlign: 'left', backgroundColor: 'grey' }}><pre>{JSON.stringify(model, null, 2)}</pre></div> */}
            </Content>

            {alert &&
                <AlertBox isError={alert.isError} onClose={() => setAlert(null)}>
                    {alert.message}
                </AlertBox>}
        </LoadingPage>
    )
}