import React from 'react';

function PageMaintenance() {
    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'fixed', top: 0, left: 0, right: 0, bottom: 0, backgroundColor: 'white' }}>
            <div style={{ textAlign: 'center' }}>
                <h1>Under Maintenance</h1>
                <p>Our application is currently undergoing maintenance. Please check back later.</p>
                <button onClick={() => window.location.reload()} >Refresh</button>
            </div>
        </div>
    );
}

export default PageMaintenance;