import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router';
import moment from 'moment';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Broker from '../../services/broker';
import { Floppy, Trash } from 'react-bootstrap-icons';
import FormInput from '../../components/FormInput';
import SearchBar from '../SearchBar';
import Content from '../Content';
import AlertBox from '../AlertBox';
import LoadingPage from '../LoadingPage';

import CommentList from '../CommentList';
import FileList from '../FileList';

export default function PageBrokerEdit() {
    const [id, setId] = useState(useParams().id);
    const [disableDelete, setDisableDelete] = useState(false);
    const [isDirty, setIsDirty] = useState(false);
    const [reload, setReload] = useState(true);
    const [alert, setAlert] = useState(null);
    const [broker, setBroker] = useState({});
    const [uploadResponseAlert, setUploadResponseAlert] = useState([]);

    const [submitting, setSubmitting] = useState(false);

    const [loading, setLoading] = useState(true);
    const [brokerLoading, setBrokerLoading] = useState(true);

    useEffect(() => {
        setLoading(brokerLoading)
    }, [brokerLoading]);

    useEffect(() => {
        setSubmitting(false);
    }, [alert, uploadResponseAlert]);

    const mounted = useRef(true);

    useEffect(() => { document.title = "Edit Broker"; }, []);

    useEffect(() => {
        mounted.current = true;
        if (!reload) {
            return;
        }

        Broker.getById(id)
            .then(item => {
                if (mounted.current) {
                    setBroker(item);
                    setDisableDelete(item.ProjectUnits && item.ProjectUnits.length > 0);
                    setBrokerLoading(false);
                    setReload(false);
                    setSubmitting(false);
                }
            })
            .catch(e => {
                //console.log(e);
                window.location.href = "/notfound";
            });
        return () => mounted.current = false;
    }, [reload]);

    useEffect(() => {
        if (isDirty) {
            setAlert(null);
            window.addEventListener("beforeunload", beforeUnloadHandler);
        }
        return () => {
            window.removeEventListener("beforeunload", beforeUnloadHandler);
        }
    }, [isDirty]);

    const beforeUnloadHandler = (event) => {
        event.preventDefault();
        event.returnValue = true;
    };

    const onFieldChanged = (field, value) => {
        if (broker) {
            setIsDirty(true);
            setBroker({ ...broker, [field]: value });
        }
    }

    const onSave = () => {
        setSubmitting(true);
        Broker.addUpdate(broker)
            .then(item => {
                if (mounted.current) {
                    if (item.error) {
                        return setAlert({ message: item.error, isError: true });
                    }

                    setId(item.id);
                    setIsDirty(false);
                    setBroker(item);
                    setAlert({ message: `Saved`, isError: false });
                }
            });
    }

    const onDelete = () => {
        if (window.confirm("Are you sure you want to delete?") === true) {
            setSubmitting(true);
            setIsDirty(false);
            Broker.delete(broker).then(item => {
                if (item.error) {
                    setAlert({ message: 'Unable to delete', isError: true });
                    return;
                }
                window.location.href = '/brokers'
            });
        } else {
            // Do nothing
        }
    }

    return (
        <LoadingPage loading={loading} submitting={submitting}>
            <SearchBar>
                <Row style={{ display: 'flex', alignItems: 'center' }}>
                    <Col sm style={{ display: 'flex', alignItems: 'center', fontStyle: 'bold' }}>
                        <a href='/brokers' style={{ paddingRight: '5px', paddingLeft: '5px' }}>Brokers</a> /
                        <span style={{ paddingRight: '5px', paddingLeft: '5px' }}>{broker.name}</span>
                    </Col>
                    {id !== '0' && !disableDelete && <Col sm="auto"><Button variant="outline-danger" onClick={() => onDelete()} title='Delete' disabled={submitting}><Trash size={30} /></Button></Col>}
                    <Col sm="auto"><Button variant="outline-success" onClick={() => onSave()} title='Save' disabled={submitting}><Floppy size={30} /></Button></Col>
                </Row>
            </SearchBar>
            <Content>
                <Row>
                    <Col>
                        <Row>
                            <Col lg>
                                <FormInput value={broker.name || ''} label='Name' onChange={(e) => onFieldChanged('name', e.target.value)} />
                            </Col>
                        </Row>
                        <Row>
                            <Col lg>
                                <FormInput value={broker.address || ''} label='Address' onChange={(e) => onFieldChanged('address', e.target.value)} />
                            </Col>
                        </Row>
                        <Row>
                            <Col lg>
                                <FormInput value={broker.contactNumber || ''} label='Contact Number' onChange={(e) => onFieldChanged('contactNumber', e.target.value)} />
                            </Col>
                            <Col lg>
                                <FormInput value={broker.email || ''} label='Email' onChange={(e) => onFieldChanged('email', e.target.value)} />
                            </Col>
                        </Row>
                        <FormInput value={broker.details || ''} label='Details' onChange={(e) => onFieldChanged('details', e.target.value)} as='textarea' style={{ height: '100px' }} />
                    </Col>
                    <Col sm='3'>
                        <FileList modelId={id} modelType='broker' />
                    </Col>
                    <Col sm='auto'>
                        {broker.ProjectUnits && broker.ProjectUnits.length > 0 &&
                            <Row className='mb-2'>
                                <Col>
                                    <Card>
                                        <Card.Header style={{ textAlign: 'center' }}>Units Sold</Card.Header>
                                        <Card.Body>
                                            {broker.ProjectUnits.map((u, i) =>
                                                <Card key={i} className='mb-2' bg='success' text='white' style={{cursor: 'pointer'}} onClick={() => window.location.href = `/projects/${u.Project.id}/units/${u.id}`}>
                                                    <Card.Body>
                                                        <Card.Title>{u.name}</Card.Title>
                                                        <Card.Subtitle>{u.Project.name}</Card.Subtitle>
                                                    </Card.Body>
                                                </Card>
                                            )}
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>
                        }
                        <Row><Col><CommentList modelId={id} /></Col></Row>

                    </Col>
                </Row>
            </Content>

            {alert &&
                <AlertBox isError={alert.isError} onClose={() => setAlert(null)}>
                    {alert.message}
                </AlertBox>}

            {/* <div style={{textAlign: 'left'}}><pre>
                {JSON.stringify(broker, null, 2)}
            </pre></div> */}
        </LoadingPage>
    )
}