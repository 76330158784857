import React, { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Form from 'react-bootstrap/Form';
import FormInput from '../../components/FormInput';
import User from '../../services/user';
import './index.css';
import FormButton from '../FormButton';

export default function PageLogin() {
    const location = useLocation();

    const [loading, setLoading] = useState(false);
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [status, setStatus] = useState('');

    const mounted = useRef(true);

    useEffect(() => { document.title = "Login"; }, []);

    useEffect(() => {
        if (localStorage.getItem('token')) {
            window.location.href = "/projects";
        } else if (location.pathname !== '/login') {
            window.location.href = "/login";
        }
    }, []);

    const onLoginSubmit = (e) => {
        e.preventDefault();
        setLoading(true);
        if (!username || !password) {
            setStatus('Username and password cannot be blank');
            setLoading(false);
            return;
        }
        User.login(username, password)
            .then(item => {
                if (item.error) {
                    setStatus('Invalid Username or password');
                    return;
                }
                if (mounted.current) {
                    setStatus('');
                    localStorage.setItem('token', item.token);
                    window.location.href = "/projects";
                }
            }).catch(err => {
                setStatus('Invalid Username or password');
            })
        setLoading(false);
    }
    return (
        <div className="Auth-form-container">
            <Form onSubmit={(e) => onLoginSubmit(e)}>
                <div className="Auth-form">
                    <div className="Auth-form-content">
                        <h3 className="Auth-form-title">Sign In</h3>
                        <div className="form-group mt-3">
                            <FormInput label='Username' value={username} onChange={(e) => setUsername(e.target.value)}></FormInput>
                        </div>
                        <div className="form-group mt-3">
                            <FormInput type="password" label='Password' value={password} onChange={(e) => setPassword(e.target.value)}></FormInput>
                        </div>
                        <div className="d-grid gap-2 mt-3">
                            <FormButton type='submit' loading={loading} onClick={(e) => onLoginSubmit(e)}>Login</FormButton>
                        </div>
                        <div className="form-group mt-3" style={{ color: 'red' }}>
                            {status}
                        </div>
                    </div>
                </div>
            </Form>
        </div>
    );
}