import { ExclamationTriangle } from "react-bootstrap-icons";

export default function NotFound() {
    return (
        <>
            <div style={{ textAlign: 'center' }}>
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <ExclamationTriangle size={100} />
                <br />
                Not Found
                <br />
            </div>
        </>
    );
}