class Global {
  async get() {
    const data = await fetch(`${process.env.REACT_APP_API_URL}/global`, {
      headers: {
        'x-access-token': localStorage.getItem('token')
      }
    });
    return await data.json();
  }

  async addUpdate(settings) {
    for (const setting of settings) {
      await fetch(`${process.env.REACT_APP_API_URL}/global/${setting.id || ''}`, {
        method: setting.id ? 'PUT' : 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-access-token': localStorage.getItem('token')
        },
        body: JSON.stringify(setting)
      });
    }
    
    return await this.get();
  }

  async delete(record) {
    const data = await fetch(`${process.env.REACT_APP_API_URL}/global/${record.id}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': localStorage.getItem('token')
      }
    });
    return await data.json();
  }
}

module.exports = new Global();