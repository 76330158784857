import React, { useEffect, useMemo, useRef, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Badge from 'react-bootstrap/Badge';
import User from '../../services/user';
import { ArrowRepeat, PlusLg } from 'react-bootstrap-icons';
import SearchBar from '../SearchBar';
import Content from '../Content';
import AlertBox from '../AlertBox';
import LoadingPage from '../LoadingPage';
import FormButton from '../FormButton';
import CellDocumentLinkRenderer from '../CellDocumentLinkRenderer';

import { AgGridReact } from 'ag-grid-react'; // AG Grid Component
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the grid
import "ag-grid-community/styles/ag-theme-quartz.css"; // Optional Theme applied to the grid
import Utils from '../../utils';

export default () => {
    const [reload, setReload] = useState(true);
    const [alert, setAlert] = useState(null);
    const [list, setList] = useState([]);
    const [searchText, setSearchText] = useState('');
    const mounted = useRef(true);

    const [submitting, setSubmitting] = useState(false);

    const [loading, setLoading] = useState(true);
    const [listLoading, setListLoading] = useState(true);

    const [colDefs, setColDefs] = useState([
        {
            field: 'username',
            cellRenderer: CellDocumentLinkRenderer,
            cellRendererParams: (params) => { return { onClick: () => onUserClicked(params.data.id), title: params.data.username } }
        },
        { field: 'name' },
        { field: 'contactNumber' },
        { field: 'email' },
        { field: 'role' },
    ]);
    const defaultColDef = useMemo(() => {
        return {
            flex: 1,
            comparator:  Utils.defaultComparator,
        };
    }, []);

    useEffect(() => {
        setLoading(listLoading)
    }, [listLoading]);

    useEffect(() => {
        setSubmitting(false);
    }, [alert]);

    useEffect(() => { document.title = "Users"; }, []);
    useEffect(() => {
        window.addEventListener("focus", () => setReload(true))
        return () => { window.removeEventListener("focus", () => setReload(true)); }
    }, []);
    
    useEffect(() => {
        mounted.current = true;
        if (list.length && !reload) {
            return;
        }

        User.get(searchText)
            .then(items => {
                if (mounted.current) {
                    if (items.error) {
                        window.location.href = "/notfound";
                        return;
                    }
                    setList(items)
                    setReload(false);
                    setListLoading(false);
                }
            })
            .catch(e => {
                window.location.href = "/notfound";
            });
        return () => mounted.current = false;
    }, [reload]);

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            setReload(true);
          }, 1000);
      
          return () => clearTimeout(delayDebounceFn);
    }, [searchText]);

    const onUserClicked = (id) => {
        window.location.href = `/users/${id}`
    }

    const onNewClicked = () => {
        window.location.href = `/users/0`
    }

    return (
        <LoadingPage loading={loading} submitting={submitting}>
            <SearchBar>
                <Row style={{ display: 'flex', alignItems: 'center' }}>
                    <Col sm="auto"><Badge bg="danger" style={{fontSize:'1.2rem'}}>{list.length}</Badge></Col>
                    <Col sm><Form.Control type="search" placeholder="Search" aria-label="Search Users" aria-describedby="basic-addon2" value={searchText} onChange={(e) => setSearchText(e.target.value)} /></Col>
                    <Col sm="auto"><Button variant="outline-success" onClick={() => onNewClicked()} title='Add new'><PlusLg size={30} /></Button></Col>
                    <Col sm="auto"><FormButton variant="outline-secondary" onClick={() => setReload(true)} title='Refresh' loading={reload}><ArrowRepeat size={30} /></FormButton></Col>
                </Row>
            </SearchBar>
            <Content>
                <div className="ag-theme-quartz" style={{ height: '600px' }}>
                    <AgGridReact
                        defaultColDef={defaultColDef}
                        columnDefs={colDefs}
                        // domLayout="autoHeight"
                        suppressScrollOnNewData='true'
                        rowData={list}
                    />
                </div>
            </Content>

            {alert &&
                <AlertBox isError={alert.isError} onClose={() => setAlert(null)}>
                    {alert.message}
                </AlertBox>}
        </LoadingPage>
    );
}