import Form from 'react-bootstrap/Form';
import FloatingLabel from 'react-bootstrap/FloatingLabel';

export default function FormSelect(props) {
    return (
        <FloatingLabel label={props.label} className="mb-3">
            <Form.Select aria-label={props.label}  {...props}>
                {props.options.map(o => <option key={o[props.indexfield]} value={o[props.indexfield]}>{o[props.field]}</option>)}
            </Form.Select>
        </FloatingLabel>
    )
}