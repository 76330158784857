import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import User from '../../services/user';
import { Floppy, Trash } from 'react-bootstrap-icons';
import FormInput from '../../components/FormInput';
import FormSelect from '../../components/FormSelect';
import SearchBar from '../SearchBar';
import Content from '../Content';
import AlertBox from '../AlertBox';
import LoadingPage from '../LoadingPage';

export default () => {
    const [id, setId] = useState(useParams().id);
    const [isDirty, setIsDirty] = useState(false);
    const [reload, setReload] = useState(true);
    const [alert, setAlert] = useState(null);
    const [user, setUser] = useState({});
    const [roles, setRoles] = useState([]);
    const [uploadResponseAlert, setUploadResponseAlert] = useState([]);

    const [submitting, setSubmitting] = useState(false);

    const [loading, setLoading] = useState(true);
    const [userLoading, setUserLoading] = useState(true);

    useEffect(() => {
        setLoading(userLoading)
    }, [userLoading]);

    useEffect(() => {
        User.getRoles().then(items => {
            setRoles(items);
        });
    }, []);

    useEffect(() => {
        setSubmitting(false);
    }, [alert, uploadResponseAlert]);

    const mounted = useRef(true);

    useEffect(() => { document.title = "Edit User"; }, []);

    useEffect(() => {
        mounted.current = true;
        if (!reload) {
            return;
        }

        User.getById(id)
            .then(item => {
                if (mounted.current) {
                    if (id === '0') {
                        item.role = 'User'
                    }
                    setUser(item);
                    setUserLoading(false);
                    setReload(false);
                    setSubmitting(false);
                }
            })
            .catch(e => {
                //console.log(e);
                window.location.href = "/notfound";
            });
        return () => mounted.current = false;
    }, [reload]);

    useEffect(() => {
        if (isDirty) {
            setAlert(null);
            window.addEventListener("beforeunload", beforeUnloadHandler);
        }
        return () => {
            window.removeEventListener("beforeunload", beforeUnloadHandler);
        }
    }, [isDirty]);

    const beforeUnloadHandler = (event) => {
        event.preventDefault();
        event.returnValue = true;
    };

    const onFieldChanged = (field, value) => {
        if (user) {
            setIsDirty(true);
            setUser({ ...user, [field]: value });
        }
    }

    const onSave = () => {
        setSubmitting(true);
        User.addUpdate(user)
            .then(item => {
                if (mounted.current) {
                    if (item.error) {
                        return setAlert({ message: item.error, isError: true });
                    }

                    setId(item.id);
                    setIsDirty(false);
                    setUser(item);
                    setAlert({ message: `Saved`, isError: false });
                }
            });
    }

    const onDelete = () => {
        if (window.confirm("Are you sure you want to delete?") === true) {
            setSubmitting(true);
            setIsDirty(false);
            User.delete(user).then(item => {
                if (item.error) {
                    setAlert({ message: 'Unable to delete', isError: true });
                    return;
                }
                window.location.href = '/users'
            });
        } else {
            // Do nothing
        }
    }

    return (
        <LoadingPage loading={loading} submitting={submitting}>
            <SearchBar>
                <Row style={{ display: 'flex', alignItems: 'center' }}>
                    <Col sm style={{ display: 'flex', alignItems: 'center', fontStyle: 'bold' }}>
                        <a href='/users' style={{ paddingRight: '5px', paddingLeft: '5px' }}>Users</a> /
                        <span style={{ paddingRight: '5px', paddingLeft: '5px' }}>{user.name || user.username}</span>
                    </Col>
                    {id !== '0' && <Col sm="auto"><Button variant="outline-danger" onClick={() => onDelete()} title='Delete' disabled={submitting}><Trash size={30} /></Button></Col>}
                    <Col sm="auto"><Button variant="outline-success" onClick={() => onSave()} title='Save' disabled={submitting}><Floppy size={30} /></Button></Col>
                </Row>
            </SearchBar>
            <Content>
                <form>
                <Row>
                    <Col>
                        <Row>
                            <Col lg>
                                <FormInput disabled={true} value={user.username || ''} label='Username' onChange={(e) => onFieldChanged('username', e.target.value)} autoComplete='username' />
                            </Col>
                            <Col lg={2}>
                                <FormSelect value={user.role} indexfield='key' label='Role' field='value' options={roles} onChange={(e) => onFieldChanged('role', e.target.value)} />
                            </Col>
                        </Row>
                        <Row>
                            <Col lg>
                                <FormInput type='password' value={user.password || ''} label='Password' onChange={(e) => onFieldChanged('password', e.target.value)} autoComplete='new-password' />
                            </Col>
                            <Col lg>
                                <FormInput type='password' value={user.confirmPassword || ''} label='Confirm Password' onChange={(e) => onFieldChanged('confirmPassword', e.target.value)} autoComplete='new-password' />
                            </Col>
                        </Row>
                        <Row>
                            <Col lg>
                                <FormInput value={user.firstname || ''} label='First Name' onChange={(e) => onFieldChanged('firstname', e.target.value)} />
                            </Col>
                            <Col lg>
                                <FormInput value={user.middlename || ''} label='Middle Name' onChange={(e) => onFieldChanged('middlename', e.target.value)} />
                            </Col>
                            <Col lg>
                                <FormInput value={user.lastname || ''} label='Last Name' onChange={(e) => onFieldChanged('lastname', e.target.value)} />
                            </Col>
                        </Row>
                        <Row>
                            <Col lg>
                                <FormInput value={user.contactNumber || ''} label='Contact Number' onChange={(e) => onFieldChanged('contactNumber', e.target.value)} />
                            </Col>
                            <Col lg>
                                <FormInput value={user.email || ''} label='Email' onChange={(e) => onFieldChanged('email', e.target.value)} />
                            </Col>
                        </Row>
                        <Row>
                            <Col lg>
                                <FormInput value={user.address || ''} label='Address' onChange={(e) => onFieldChanged('address', e.target.value)} />
                            </Col>
                        </Row>
                    </Col>
                </Row>
                </form>
            </Content>

            {alert &&
                <AlertBox isError={alert.isError} onClose={() => setAlert(null)}>
                    {alert.message}
                </AlertBox>}

            {/* <div style={{textAlign: 'left'}}><pre>
                {JSON.stringify(user, null, 2)}
            </pre></div> */}
        </LoadingPage>
    )
}