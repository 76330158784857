import React, { useEffect, useRef, useState } from "react";
import Form from 'react-bootstrap/Form';
import FormControl from 'react-bootstrap/FormControl';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ToastContainer from 'react-bootstrap/ToastContainer';
import Toast from 'react-bootstrap/Toast';
import { PlusLg } from 'react-bootstrap-icons';
import Button from 'react-bootstrap/Button';
import CommentItem from "./CommentItem";
import Comment from "../../services/comment";

export default (params) => {
    const mounted = useRef(true);
    const [modelId, setModelId] = useState(params.modelId);
    const [reload, setReload] = useState(true);
    const [comments, setComments] = useState([]);
    const [newComment, setNewComment] = useState('');
    const [submitting, setSubmitting] = useState(false);

    useEffect(() => {
        setModelId(params.modelId);
    }, [params]);
    
    useEffect(() => {
        mounted.current = true;
        if (!reload) {
            return;
        }

        Comment.get(modelId, '')
            .then(c => {
                if (mounted.current) {
                    setComments(c);
                    setReload(false);
                }
            });

        return () => mounted.current = false;
    }, [reload]);

    const onAddClick = (e) => {
        e.preventDefault();
        setSubmitting(true);
        Comment.add(modelId, { details: newComment })
            .then(() => { setNewComment(''); setReload(true); setSubmitting(false); });
    }

    return (
        <>
            {modelId !== '0' &&
                <ToastContainer className="position-static">

                    <Toast bg='light'>
                        <Toast.Header closeButton={false}>
                            <strong className="me-auto"></strong>
                            <small className="text-muted">New Comment</small>
                        </Toast.Header>
                        <Toast.Body>
                            <Form onSubmit={(e) => onAddClick(e)}>
                                <Row>
                                    <Col><FormControl type="search" value={newComment} onChange={(e) => setNewComment(e.target.value)} disabled={submitting} /></Col>
                                    <Col sm='auto'><Button variant="success" onClick={(e) => onAddClick(e)} disabled={submitting}><PlusLg /></Button></Col>
                                </Row>
                            </Form>
                        </Toast.Body>
                    </Toast>

                    {comments.map(comment =>
                        <CommentItem key={comment.id} comment={comment} onChange={() => setReload(true)} />
                    )}
                </ToastContainer>
            }

            {modelId === '0' &&
                <div className='text-center' style={{ color: 'white' }}>Save to start adding comments</div>
            }
        </>
    )
}