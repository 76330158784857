import React, { useEffect, useRef, useState, useMemo } from 'react';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import AlertBox from '../AlertBox';

import { AgGridReact } from 'ag-grid-react'; // AG Grid Component
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the grid
import "ag-grid-community/styles/ag-theme-quartz.css"; // Optional Theme applied to the grid
import CellDocumentLinkRenderer from '../CellDocumentLinkRenderer';
import CellDelete from '../CellDelete';
import CellStatus from '../CellStatus';
import File from "../../services/file";
import FormButton from '../FormButton';
import Template from '../../services/template';
import Utils from '../../utils';

export default (params) => {
    const mounted = useRef(true);
    const [modelType, setModelType] = useState(params.modelType);
    const [modelId, setModelId] = useState(params.modelId);
    const [reload, setReload] = useState(true);
    const [files, setFiles] = useState([]);
    const [deleteFileName, setDeleteFileName] = useState('');
    const [submitting, setSubmitting] = useState(false);
    const [uploadResponseAlert, setUploadResponseAlert] = useState([]);
    const [templateSelector, setTemplateSelector] = useState(false);
    const [templates, setTemplates] = useState([]);

    const [loading, setLoading] = useState(true);
    const [projectFilesLoading, setProjectFilesLoading] = useState(true);

    const [colDefs, setColDefs] = useState([
        {
            field: 'name',
            headerName: 'Documents',
            cellRenderer: CellDocumentLinkRenderer,
            cellRendererParams: (params) => { return { onClick: () => File.download(modelId, params.data.name).then(() => { }), title: params.data.name } }
        },
        {
            headerName: 'Type',
            maxWidth: 70,
            valueGetter: p => p.data.name?.split('.').slice(-1).pop()
        },
        {
            field: 'delete',
            headerName: '',
            maxWidth: 55,
            cellRenderer: CellDelete,
            cellRendererParams: (params) => { return { onClick: () => setDeleteFileName(params.data.name) } },
            sortable: false
        }
    ]);
    const [colDefsUploadedFiles, setColDefsUploadedFiles] = useState([
        {
            headerName: '',
            maxWidth: 55,
            cellRenderer: CellStatus,
            cellRendererParams: (params) => { return { error: params.data.error } },
            valueGetter: p => p.data.error ? '1' : '2'
        },
        {
            field: 'name',
            flex: 2
        },
        {
            field: 'error',
            flex: 1,
            valueGetter: p => p.data.error ?? 'Success'
        }
    ]);
    const [colDefsTemplates, setColDefsTemplates] = useState([
        {
            headerName: 'Template',
            field: 'name',
            cellRenderer: CellDocumentLinkRenderer,
            cellRendererParams: (params) => { return { onClick: () => window.location.href = `/templates/new?template=${params.data.name}&modelType=${modelType}&modelId=${modelId}`, title: params.data.name } }
        }
    ]);
    const defaultColDef = useMemo(() => {
        return {
            flex: 1,
            comparator: Utils.defaultComparator,
        };
    }, []);

    useEffect(() => {
        setModelId(params.modelId);
    }, [params]);

    useEffect(() => {
        setLoading(projectFilesLoading)
    }, [projectFilesLoading]);

    useEffect(() => {
        setSubmitting(false);
    }, [uploadResponseAlert]);

    useEffect(() => {
        if (templateSelector) {
            Template.get('')
                .then(items => setTemplates(items));
        }
        setSubmitting(false);
    }, [templateSelector]);

    useEffect(() => {
        mounted.current = true;
        if (!reload) {
            return;
        }

        File.get(modelId)
            .then(f => {
                if (mounted.current) {
                    setFiles(f);
                    setReload(false);
                }
            });

        return () => mounted.current = false;
    }, [reload]);

    useEffect(() => {
        if (submitting) {
            setDeleteFileName('');
            return;
        }

        if (deleteFileName === '') {
            return;
        }

        if (window.confirm("Are you sure you want to delete?") === true) {
            setSubmitting(true);
            File.delete(modelId, deleteFileName)
                .then(() => { setReload(true); setSubmitting(false); });
        }

        setDeleteFileName('');

    }, [deleteFileName])

    const uploadFiles = async (e) => {
        setSubmitting(true);
        setUploadResponseAlert(await File.upload(modelId, e.target.files));
        e.target.value = '';
        setReload(true);
    }

    return (
        <>
            {modelId !== '0' &&
                <>
                    <Row className='mb-2'>
                        <Form.Control type="file" name="file" multiple onChange={(e) => uploadFiles(e)} disabled={submitting} />
                    </Row>
                    <Row className='mb-2'>
                        <FormButton variant='light' onClick={() => setTemplateSelector(true)}>Or create from template</FormButton>
                    </Row>
                    <Row>
                        <div className="ag-theme-quartz" style={{ height: '25rem', paddingLeft: '0px', paddingRight: '0px' }}>
                            <AgGridReact
                                defaultColDef={defaultColDef}
                                columnDefs={colDefs}
                                // domLayout="autoHeight"
                                suppressScrollOnNewData='true'
                                rowData={files}
                            />
                        </div>
                    </Row>
                </>

            }
            {modelId === '0' &&
                <div className='text-center' style={{ color: 'white' }}>Save to start adding documents</div>
            }

            {uploadResponseAlert && uploadResponseAlert.length > 0 &&
                <AlertBox isError={uploadResponseAlert.findIndex(r => r.error) !== -1} onClose={() => setUploadResponseAlert([])}>
                    {uploadResponseAlert.length === 1 &&
                        <div>{uploadResponseAlert[0].error ? uploadResponseAlert[0].error : 'File uploaded successfully'}</div>
                    }
                    {uploadResponseAlert.length > 1 &&
                        <div className="ag-theme-quartz" style={{ height: '20rem', width: '28rem' }}>
                            <AgGridReact
                                defaultColDef={defaultColDef}
                                columnDefs={colDefsUploadedFiles}
                                // domLayout="autoHeight"
                                suppressScrollOnNewData='true'
                                rowData={uploadResponseAlert}
                            />
                        </div>}
                </AlertBox>}

            {templateSelector &&
                <AlertBox isError={false} onClose={() => setTemplateSelector(false)}>
                    <div className="ag-theme-quartz" style={{ height: '20rem', width: '28rem' }}>
                        <AgGridReact
                            defaultColDef={defaultColDef}
                            columnDefs={colDefsTemplates}
                            // domLayout="autoHeight"
                            suppressScrollOnNewData='true'
                            rowData={templates}
                        />
                    </div>
                </AlertBox>}
        </>
    )
}