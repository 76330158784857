class Customer {
  async get(filter) {
    const data = await fetch(`${process.env.REACT_APP_API_URL}/customers?q=${filter}`, {
      headers: {
        'x-access-token': localStorage.getItem('token')
      }
    });
    return await data.json();
  }

  async getById(id) {
    if (id === '0') {
      return {};
    }
    const data = await fetch(`${process.env.REACT_APP_API_URL}/customers/${id}`, {
      headers: {
        'x-access-token': localStorage.getItem('token')
      }
    });
    return await data.json();
  }

  async addUpdate(record) {
    const data = await fetch(`${process.env.REACT_APP_API_URL}/customers/${record.id || ''}`, {
      method: record.id ? 'PUT' : 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': localStorage.getItem('token')
      },
      body: JSON.stringify(record)
    });
    return await data.json();
  }

  async delete(record) {
    const data = await fetch(`${process.env.REACT_APP_API_URL}/customers/${record.id}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': localStorage.getItem('token')
      }
    });
    return await data.json();
  }

  async export() {
    const data = await fetch(`${process.env.REACT_APP_API_URL}/customers/export`, {
      headers: {
        'x-access-token': localStorage.getItem('token')
      }
    });

    const blob = await data.blob();

    // Creating new object of file
    const fileURL = window.URL.createObjectURL(blob);

    // Setting various property values
    let alink = document.createElement("a");
    alink.href = fileURL;
    alink.download = 'customers.csv';
    alink.click();

    return;
  }
}

module.exports = new Customer();