import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useParams } from 'react-router';
import moment from 'moment';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Offcanvas from 'react-bootstrap/Offcanvas';
import ProgressBar from 'react-bootstrap/ProgressBar';
import ProjectUnit from '../../services/projectUnit';
import ProjectUnitPayments from '../../services/projectUnitPayments';
import { ArrowRepeat, Calendar3, Eye, Newspaper, PlusLg } from 'react-bootstrap-icons';
import SearchBar from '../SearchBar';
import Content from '../Content';
import AlertBox from '../AlertBox';
import LoadingPage from '../LoadingPage';
import FormInput from '../FormInput';
import FormSelect from '../FormSelect';
import FormButton from '../FormButton';
import CellDelete from '../CellDelete';
import './index.css';

import { AgGridReact } from 'ag-grid-react'; // AG Grid Component
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the grid
import "ag-grid-community/styles/ag-theme-quartz.css"; // Optional Theme applied to the grid
import Utils from '../../utils';

export default function PageProjectUnits() {
    const mounted = useRef(true);
    const [projectId, setProjectId] = useState(useParams().projectId);
    const [id, setId] = useState(useParams().id);
    const [isDirty, setIsDirty] = useState(false);
    const [marginTypes, setMarginTypes] = useState([]);
    const [projectUnit, setProjectUnit] = useState({});
    const [projectUnitPayment, setProjectUnitPayment] = useState({ date: moment().format('YYYY-MM-DD'), projectUnitId: useParams().id, marginType: 'Cheque' });
    const [reload, setReload] = useState(true);
    const [alert, setAlert] = useState(null);
    const [list, setList] = useState([]);
    const [paymentSchedule, setPaymentSchedule] = useState([]);
    const [deletePaymentId, setDeletePaymentId] = useState('');

    const [cashAmount, setCashAmount] = useState(0.00);
    const [totalDeedAmount, setTotalDeedAmount] = useState(0.00);
    const [totalAmount, setTotalAmount] = useState(0.00);
    const [cashAmountPaid, setCashAmountPaid] = useState(0.00);
    const [totalDeedAmountPaid, setTotalDeedAmountPaid] = useState(0.00);
    const [totalPaid, setTotalPaid] = useState(0.00);
    const [cashAmountPaidPercentage, setCashAmountPaidPercentage] = useState(0.00);
    const [totalDeedAmountPaidPercentage, setTotalDeedAmountPaidPercentage] = useState(0.00);
    const [totalPaidPercentage, setTotalPaidPercentage] = useState(0.00);

    const [showPaymentSchedule, setShowPaymentSchedule] = useState(false);

    const [submitting, setSubmitting] = useState(false);

    const [loading, setLoading] = useState(true);
    const [projectUnitLoading, setProjectUnitLoading] = useState(true);
    const [listLoading, setListLoading] = useState(true);

    const [colDefs, setColDefs] = useState([
        { field: 'date', valueGetter: (params) => params.data.date ? moment(params.data.date).format('DD-MM-YYYY') : '' },
        { field: 'amount', type: 'numericColumn', valueFormatter: (params) => params.value ? toINR(params.value) : '' },
        { field: 'mode' },
        { field: 'marginType' },
        { field: 'remarks' },
        {
            field: 'delete',
            headerName: '',
            maxWidth: 55,
            cellRenderer: CellDelete,
            cellRendererParams: (params) => { return { onClick: () => setDeletePaymentId(params.data.id) } },
            sortable: false
        },
    ]);

    const defaultColDef = useMemo(() => {
        return {
            flex: 1,
            comparator:  Utils.defaultComparator,
        };
    }, []);

    useEffect(() => {
        setLoading(listLoading || projectUnitLoading);
    }, [listLoading, projectUnitLoading]);

    useEffect(() => {
        ProjectUnitPayments.getMarginTypes()
            .then(items => {
                setMarginTypes(items);
            });

        ProjectUnit.getById(id)
            .then(item => {
                if (mounted.current) {
                    setProjectUnit(item);
                    setProjectUnitLoading(false);
                }
            })
            .catch(e => {
                window.location.href = "/notfound";
            });
    }, []);

    useEffect(() => {
        if (submitting) {
            setDeletePaymentId('');
            return;
        }

        if (deletePaymentId === '') {
            return;
        }

        if (window.confirm("Are you sure you want to delete?") === true) {
            setSubmitting(true);
            ProjectUnitPayments.delete(deletePaymentId)
                .then(() => { setReload(true); setSubmitting(false); });
        }

        setDeletePaymentId('');

    }, [deletePaymentId]);

    useEffect(() => {
        var newArea = projectUnit.areaType === 'SBA' ? parseFloat(projectUnit.superBuiltUpArea) : projectUnit.areaType === 'BA' ? parseFloat(projectUnit.builtUpArea) : parseFloat(projectUnit.carpetArea);
        const newDealAmount = toFixed(parseFloat(newArea) * parseFloat(projectUnit.pricePerSqft));
        const newCashAmount = toFixed(parseFloat(newDealAmount) - parseFloat(projectUnit.saleDeedAmount));
        const newGstAmount = toFixed(parseFloat(projectUnit.saleDeedAmount) * parseFloat(projectUnit.gstPercentage) / 100.0);
        const newStampDutyAmount = toFixed(parseFloat(projectUnit.saleDeedAmount) * parseFloat(projectUnit.stampDutyPercentage) / 100.0);
        const newTotalCostAdditions = toFixed(parseFloat(newGstAmount) + parseFloat(newStampDutyAmount) + projectUnit?.ProjectUnitCostAdditions?.reduce((a, b) => a + parseFloat(b.amount), 0));
        const newTotalDeedAmount = toFixed(parseFloat(projectUnit.saleDeedAmount) + parseFloat(newTotalCostAdditions));
        const newTotalAmount = toFixed(parseFloat(newTotalDeedAmount) + parseFloat(newCashAmount));

        const newCashAmountPaid = list.filter(x => x.marginType === 'Cash').reduce((a, b) => a + parseFloat(b.amount), 0);
        const newTotalDeedAmountPaid = list.filter(x => x.marginType === 'Cheque').reduce((a, b) => a + parseFloat(b.amount), 0);
        const newTotalPaid = toFixed(parseFloat(newCashAmountPaid) + parseFloat(newTotalDeedAmountPaid));

        const newCashAmountPaidPercentage = toFixed(parseFloat(newCashAmountPaid) * 100 / parseFloat(newCashAmount));
        const newTotalDeedAmountPaidPercentage = toFixed(parseFloat(newTotalDeedAmountPaid) * 100 / parseFloat(newTotalDeedAmount));
        const newTotalPaidPercentage = toFixed(parseFloat(newTotalPaid) * 100 / parseFloat(newTotalAmount));

        setCashAmount(newCashAmount);
        setTotalDeedAmount(newTotalDeedAmount);
        setTotalAmount(newTotalAmount);
        setCashAmountPaid(newCashAmountPaid);
        setTotalDeedAmountPaid(newTotalDeedAmountPaid);
        setTotalPaid(newTotalPaid);
        setCashAmountPaidPercentage(newCashAmountPaidPercentage);
        setTotalDeedAmountPaidPercentage(newTotalDeedAmountPaidPercentage);
        setTotalPaidPercentage(newTotalPaidPercentage);

        var newPaymentSchedule = [];
        const today = moment().add(-1, 'days');

        const getPaymentSchedule = (item) => {
            const totalPaid = item.marginType === 'Cheque' ? parseFloat(newTotalDeedAmountPaid) : parseFloat(newCashAmountPaid);
            const dueUntil = orderedPaymentSchedule.filter(x => x.marginType === item.marginType).reduce((a, b) => a + parseFloat(b.amount), 0) + parseFloat(item.amount);
            item.balance = dueUntil - totalPaid;
            item.balance = item.balance < 0 ? 0 : item.balance;

            item.isPaid = item.balance <= 0;
            item.isOverdue = !item.isPaid && item.date.diff(today) < 1;
            item.className = item.isPaid ? 'paid' : item.isOverdue ? 'overdue' : '';

            return item;
        }

        // cheque margins
        newPaymentSchedule = [...newPaymentSchedule, { date: moment(projectUnit.chequeTokenAmountEndDate), amount: projectUnit.chequeTokenAmount, marginType: 'Cheque' }];
        newPaymentSchedule = [...newPaymentSchedule, { date: moment(projectUnit.chequeMarginAmountEndDate), amount: projectUnit.chequeMarginAmount, marginType: 'Cheque' }];
        newPaymentSchedule = [...newPaymentSchedule, { date: moment(projectUnit.paymentTermsEndDate), amount: projectUnit.balanceTillSaleDeed, marginType: 'Cheque' }];

        // cash margins
        newPaymentSchedule = [...newPaymentSchedule, { date: moment(projectUnit.cashTokenAmountEndDate), amount: projectUnit.cashTokenAmount, marginType: 'Cash' }];
        newPaymentSchedule = [...newPaymentSchedule, { date: moment(projectUnit.cashMarginAmountEndDate), amount: projectUnit.cashMarginAmount, marginType: 'Cash' }];

        // add installments
        if (projectUnit.ProjectUnitInstallments) {
            newPaymentSchedule = [...newPaymentSchedule, ...projectUnit.ProjectUnitInstallments.map(x => { return { date: moment(x.date), amount: x.amount, marginType: x.marginType } })];
        }

        // sort by date
        newPaymentSchedule = newPaymentSchedule.sort((a, b) => {
            if (a.date < b.date) { return -1; }
            if (a.date > b.date) { return 1; }
            return 0;
        });

        let orderedPaymentSchedule = [];
        newPaymentSchedule.forEach((item) => {
            orderedPaymentSchedule.push(getPaymentSchedule(item));
        });

        setPaymentSchedule(orderedPaymentSchedule);
    }, [projectUnit, list]);

    useEffect(() => {
        setSubmitting(false);
    }, [alert]);

    useEffect(() => { document.title = "Project Unit Payments"; }, []);
    useEffect(() => {
        window.addEventListener("focus", () => setReload(true))
        return () => { window.removeEventListener("focus", () => setReload(true)); }
    }, []);

    useEffect(() => {
        mounted.current = true;
        if (list.length && !reload) {
            return;
        }

        ProjectUnitPayments.get(id)
            .then(items => {
                if (mounted.current) {

                    items = items.map((item, index) => {
                        item.cumulative = items.slice(0, index + 1).reduce((a, b) => a + parseFloat(b.amount), 0);
                        return item;
                    });                    

                    setList(items)
                    setReload(false);
                    setListLoading(false);
                }
            })
        return () => mounted.current = false;
    }, [reload]);

    useEffect(() => {
        if (isDirty) {
            setAlert(null);
            window.addEventListener("beforeunload", beforeUnloadHandler);
        }
        return () => {
            window.removeEventListener("beforeunload", beforeUnloadHandler);
        }
    }, [isDirty]);

    const beforeUnloadHandler = (event) => {
        event.preventDefault();
        event.returnValue = true;
    };

    const onFieldChanged = (field, value) => {
        if (projectUnitPayment) {
            setIsDirty(true);
            setProjectUnitPayment({ ...projectUnitPayment, [field]: value });
        }
    }

    const toFloat = (value) => {
        var val = value;
        if (isNaN(val) || !isFinite(val) || val === '' || val === null || val === undefined || val === 'null' || val === 'undefined' || val === 'NaN' || val === 'Infinity' || val === '-Infinity' || val === '-NaN') {
            val = 0;
        }
        return parseFloat(val);
    }

    const toFixed = (value) => {
        return toFloat(value).toFixed(2);
    }

    const toPercentage = (value) => {
        return `${toFixed(value)}%`;
    }

    const toINR = (value) => {
        var val = toFloat(value);
        return parseFloat(val).toLocaleString('en-IN', { style: 'currency', currency: 'INR' });
    }

    const onAddPayment = () => {
        // validate
        setSubmitting(true);
        ProjectUnitPayments.add(projectUnitPayment)
            .then((item) => {
                if (mounted.current) {
                    if (item.error) {
                        return setAlert({ message: item.error, isError: true });
                    }

                    setAlert({ isError: false, message: 'Payment added successfully' });
                    setProjectUnitPayment({ date: moment().format('YYYY-MM-DD'), projectUnitId: id, marginType: 'Cheque' });
                    setIsDirty(false);
                    setReload(true);
                }
            })
            .catch(e => {
                setAlert({ isError: true, message: e.message });
            });
    }

    const onPaymentScheduleRowClick = (item) => {
        if (item.isPaid) {
            return;
        }

        setProjectUnitPayment({ ...item, projectUnitId: id, date: moment().format('YYYY-MM-DD'), amount: toFixed(item.balance), mode: item.marginType, marginType: item.marginType });
        setShowPaymentSchedule(false);
    }

    return (
        <LoadingPage loading={loading} submitting={submitting}>
            <SearchBar>
                <Row style={{ display: 'flex', alignItems: 'center' }}>
                    <Col sm style={{ display: 'flex', alignItems: 'center', fontStyle: 'bold' }}>
                        <a href='/projects' style={{ paddingRight: '5px', paddingLeft: '5px' }}>Projects</a> /
                        <a href={`/projects/${projectId}/units`} style={{ paddingRight: '5px', paddingLeft: '5px' }}>{projectUnit?.Project?.name}</a> /
                        <a href={`/projects/${projectId}/units/${id}`} style={{ paddingRight: '5px', paddingLeft: '5px' }}>{projectUnit.name}</a> /
                        <span style={{ paddingRight: '5px', paddingLeft: '5px' }}>Payments</span>
                    </Col>
                    <Col sm="auto"><Button variant="outline-dark" onClick={() => setShowPaymentSchedule(true)} title='Payment Schedule' disabled={submitting}><Calendar3 size={30} /></Button></Col>
                    <Col sm="auto"><Button variant="outline-danger" onClick={() => window.location.href = `/projects/${projectId}/units/${id}/sc`} title='Soda Chitthi' disabled={submitting}><Newspaper size={30} /></Button></Col>
                </Row>
            </SearchBar>
            <Content>
                <Row>
                    <Col lg>
                        <Row>
                            <Col lg>
                                <Table bordered hover className='payments-table'>
                                    <thead>
                                        <tr>
                                            <th rowSpan={2}></th>
                                            <th>Cheque</th>
                                            <th>Cash</th>
                                            <th>Total</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td></td>
                                            <td>
                                                <ProgressBar>
                                                    <ProgressBar variant='success' now={parseFloat(totalDeedAmountPaidPercentage)} label={toPercentage(totalDeedAmountPaidPercentage)} />
                                                    <ProgressBar variant='danger' now={100 - parseFloat(totalDeedAmountPaidPercentage)} label={toPercentage(100 - parseFloat(totalDeedAmountPaidPercentage))} />
                                                </ProgressBar>
                                            </td>
                                            <td>
                                                <ProgressBar>
                                                    <ProgressBar variant='success' now={parseFloat(cashAmountPaidPercentage)} label={toPercentage(cashAmountPaidPercentage)} />
                                                    <ProgressBar variant='danger' now={100 - parseFloat(cashAmountPaidPercentage)} label={toPercentage(100 - parseFloat(cashAmountPaidPercentage))} />
                                                </ProgressBar>
                                            </td>
                                            <td>
                                                <ProgressBar>
                                                    <ProgressBar variant='success' now={parseFloat(totalPaidPercentage)} label={toPercentage(totalPaidPercentage)} />
                                                    <ProgressBar variant='danger' now={100 - parseFloat(totalPaidPercentage)} label={toPercentage(100 - parseFloat(totalPaidPercentage))} />
                                                </ProgressBar>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>Paid</th>
                                            <td>{toINR(totalDeedAmountPaid)}</td>
                                            <td>{toINR(cashAmountPaid)}</td>
                                            <td>{toINR(totalPaid)}</td>
                                        </tr>
                                        <tr>
                                            <th>Balance</th>
                                            <td>{toINR(toFloat(totalDeedAmount) - toFloat(totalDeedAmountPaid))}</td>
                                            <td>{toINR(toFloat(cashAmount) - toFloat(cashAmountPaid))}</td>
                                            <td>{toINR(toFloat(totalAmount) - toFloat(totalPaid))}</td>
                                        </tr>
                                        <tr className='topLine'>
                                            <th>Total</th>
                                            <td>{toINR(totalDeedAmount)}</td>
                                            <td>{toINR(cashAmount)}</td>
                                            <td>{toINR(totalAmount)}</td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </Col>
                        </Row>
                        <FormInput type="date" value={moment(projectUnitPayment.date).format('YYYY-MM-DD') || ''} label='Date' onChange={(e) => onFieldChanged('date', e.target.value)} />
                        <FormInput type="currency" value={projectUnitPayment.amount || ''} label='Amount' onChange={(e) => onFieldChanged('amount', e.target.value)} />
                        <FormInput type="text" value={projectUnitPayment.mode || ''} label='Mode' onChange={(e) => onFieldChanged('mode', e.target.value)} />
                        <FormSelect value={projectUnitPayment.marginType} indexfield='key' label='Margin Type' field='value' options={marginTypes} onChange={(e) => onFieldChanged('marginType', e.target.value)} />
                        <FormInput type="text" value={projectUnitPayment.remarks || ''} label='Remarks' as='textarea' style={{ height: '100px' }} onChange={(e) => onFieldChanged('remarks', e.target.value)} />
                        <div style={{ textAlign: 'center' }}>
                            <FormButton title='Add Payment' variant='success' onClick={onAddPayment} disabled={submitting} >Add Payment</FormButton>
                        </div>
                        <br />
                    </Col>
                    <Col lg={7}>
                        <div className="ag-theme-quartz" style={{ height: '600px' }}>
                            <AgGridReact
                                defaultColDef={defaultColDef}
                                columnDefs={colDefs}
                                // domLayout="autoHeight"
                                suppressScrollOnNewData='true'
                                rowData={list}
                            />
                        </div>
                    </Col>
                </Row>
            </Content>

            {alert &&
                <AlertBox isError={alert.isError} onClose={() => setAlert(null)}>
                    {alert.message}
                </AlertBox>}

            <Offcanvas placement='end' show={showPaymentSchedule} onHide={() => setShowPaymentSchedule(false)}>
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>Payment Schedule</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <Table bordered hover className='payment-schedule-table'>
                        <thead>
                            <tr>
                                <th colSpan={4}>Payment Schedule</th>
                            </tr>
                            <tr>
                                <th>Date</th>
                                <th>Amount</th>
                                <th>Margin Type</th>
                                <th>Balance</th>
                            </tr>
                        </thead>
                        <tbody>
                            {paymentSchedule.map((item, index) => (
                                <tr key={index} className={item.className} onClick={() => onPaymentScheduleRowClick(item)}>
                                    <td>{moment(item.date).format('DD-MM-YYYY')}</td>
                                    <td className='number'>{toINR(item.amount)}</td>
                                    <td>{item.marginType}</td>
                                    <td>{toINR(item.balance)}</td>
                                </tr>
                            ))}
                            <tr>
                                <th>Total</th>
                                <th className='number'>{toINR(paymentSchedule.reduce((total, item) => total + toFloat(item.amount), 0))}</th>
                                <td colSpan={2}></td>
                            </tr>
                        </tbody>
                    </Table>
                </Offcanvas.Body>
            </Offcanvas>
        </LoadingPage>
    );
}