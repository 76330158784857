import React from 'react';
import { useState, useEffect } from 'react';
import Project from '../../services/project';

export default ({ id, reset }) => {
    const [imageUrl, setImageUrl] = useState('');
    const [altText, setAltText] = useState('');

    useEffect(() => {
        Project.getImage(id)
            .then(image => {
                setImageUrl(image);
                setAltText(`Image not found`);
            });
    }, [id, reset]);

    return (
        <img
            src={imageUrl} alt={altText} style={{ height: '30rem', objectFit: 'cover', objectPosition: '25% 25%' }}
            onError={event => {
                event.target.src = `/default-project.png`
                event.onerror = null
            }} />
    );
};
