class ProjectUnit {
  async getTypes() {
    const data = await fetch(`${process.env.REACT_APP_API_URL}/projectunits/types`, {
      headers: {
        'x-access-token': localStorage.getItem('token')
      }
    });
    return await data.json();
  }

  async getStatuses() {
    const data = await fetch(`${process.env.REACT_APP_API_URL}/projectunits/statuses`, {
      headers: {
        'x-access-token': localStorage.getItem('token')
      }
    });
    return await data.json();
  }

  async getAreaTypes() {
    const data = await fetch(`${process.env.REACT_APP_API_URL}/projectunits/areaTypes`, {
      headers: {
        'x-access-token': localStorage.getItem('token')
      }
    });
    return await data.json();
  }

  async get(projectId, filter) {
    const data = await fetch(`${process.env.REACT_APP_API_URL}/projectunits/project/${projectId}?q=${filter}`, {
      headers: {
        'x-access-token': localStorage.getItem('token')
      }
    });
    return await data.json();
  }

  async getById(id) {
    if (id === '0') {
      return {};
    }
    const data = await fetch(`${process.env.REACT_APP_API_URL}/projectunits/${id}`, {
      headers: {
        'x-access-token': localStorage.getItem('token')
      }
    });
    return await data.json();
  }

  async addUpdate(record) {
    const data = await fetch(`${process.env.REACT_APP_API_URL}/projectunits/${record.id || ''}`, {
      method: record.id ? 'PUT' : 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': localStorage.getItem('token')
      },
      body: JSON.stringify(record)
    });
    return await data.json();
  }

  async delete(record) {
    const data = await fetch(`${process.env.REACT_APP_API_URL}/projectunits/${record.id}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': localStorage.getItem('token')
      }
    });
    return await data.json();
  }

  async export(projectId) {
    const data = await fetch(`${process.env.REACT_APP_API_URL}/projectunits/project/${projectId}/export`, {
      headers: {
        'x-access-token': localStorage.getItem('token')
      }
    });

    const blob = await data.blob();

    // Creating new object of file
    const fileURL = window.URL.createObjectURL(blob);

    // Setting various property values
    let alink = document.createElement("a");
    alink.href = fileURL;
    alink.download = 'projectunit.csv';
    alink.click();

    return;
  }

  async import(projectId, file) {
    const formData = new FormData();
    formData.append('csv', file);

    const data = await fetch(`${process.env.REACT_APP_API_URL}/projectunits/project/${projectId}/import`, {
      method: 'POST',
      headers: {
        'x-access-token': localStorage.getItem('token')
      },
      body: formData
    });
    return await data.json();
  }

  async addUnitCustomer(puid, cid) {
    const data = await fetch(`${process.env.REACT_APP_API_URL}/projectunitcustomers`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': localStorage.getItem('token')
      },
      body: JSON.stringify({ projectUnitId: puid, customerId: cid })
    });
    return await data.json();
  }

  async updateUnitCustomer(puid, cid, orderNumber) {
    const data = await fetch(`${process.env.REACT_APP_API_URL}/projectunitcustomers/${puid}/${cid}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': localStorage.getItem('token')
      },
      body: JSON.stringify({ orderNumber: orderNumber })
    });
    return await data.json();
  }

  async deleteUnitCustomer(puid, cid) {
    const data = await fetch(`${process.env.REACT_APP_API_URL}/projectunitcustomers/${puid}/${cid}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': localStorage.getItem('token')
      }
    });
    return;
  }
}

module.exports = new ProjectUnit();