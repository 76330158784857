import { createRef, useEffect, useRef, useState } from 'react';
import Form from 'react-bootstrap/Form';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import './index.css';

export default function FormInput(props) {
    const [showAsCurrency, setShowAsCurrency] = useState(props.type === 'currency' || false);
    const inputRef = useRef(null);
    const isCurrency = props.type === 'currency';
    const type = isCurrency ? 'number' : props.type;

    var isValid = !props.valid || props.valid === 'true' ? true : false;
    var title = props.title || '';
    var value = props.value || '';
    const minvalue = props.minvalue;
    const maxvalue = props.maxvalue;
    const equalto = props.equalto;
    const required = props.required || props.required === 'true';

    useEffect(() => {
        if (!showAsCurrency) {
            inputRef.current?.focus();
            inputRef.current?.select();
        }
      }, [showAsCurrency]);

    const toINR = (val) => {
        return val ? parseFloat(val).toLocaleString('en-IN', { style: 'currency', currency: 'INR' }) : '';
    }

    if (required && isValid) {
        if (!value) {
            isValid = false;
            title = 'This field is required';
        }
    }

    if (minvalue && isValid) {
        if (!value || parseFloat(value) < parseFloat(minvalue)) {
            isValid = false;
            title = `Value must be greater than or equal to ${isCurrency ? toINR(minvalue) : minvalue}`;
        }
    }

    if (maxvalue && isValid) {
        if (!value || parseFloat(value) > parseFloat(maxvalue)) {
            isValid = false;
            title = `Value must be less than or equal to ${isCurrency ? toINR(maxvalue) : maxvalue}`;
        }
    }

    if (equalto && isValid) {
        if (!value || parseFloat(props.value) !== parseFloat(equalto)) {
            isValid = false;
            title = `Value must be equal to ${isCurrency ? toINR(equalto) : equalto}`;
        }
    }

    const onBlur = (e) => {
        if (isCurrency) {
            setShowAsCurrency(true);
        }
    }

    const onFocus = (e) => {
        if (isCurrency) {
            setShowAsCurrency(false);
        }
    }

    if (!props.label) {
        return (
            <div className={`${isValid ? '' : 'form-input-error'}`}>
                {showAsCurrency && <Form.Control  placeholder={props.label} title={title} {...props} type='text' value={toINR(value)} onFocus={(e) => onFocus(e)} />}
                {!showAsCurrency && <Form.Control ref={inputRef} placeholder={props.label} title={title} {...props} type={type} onBlur={(e) => onBlur(e)} />}
            </div>
        )
    }

    return (
        <FloatingLabel label={props.label} className={`mb-3 ${isValid ? '' : 'form-input-error'}`}>
            {showAsCurrency && <Form.Control placeholder={props.label} title={title} {...props} type='text' value={toINR(value)} onFocus={() => onFocus()} />}
            {!showAsCurrency && <Form.Control ref={inputRef} placeholder={props.label} title={title} {...props} type={type} onBlur={() => onBlur()} />}
        </FloatingLabel>
    )
}