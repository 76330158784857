class File {
    async get(modelId) {
    if (modelId === '0') {
      return [];
    }
    const data = await fetch(`${process.env.REACT_APP_API_URL}/files/${modelId}`, {
      headers: {
        'x-access-token': localStorage.getItem('token')
      }
    });
    return await data.json();
  }

  async download(modelId, file) {
    if (modelId === '0') {
      return {};
    }
    const data = await fetch(`${process.env.REACT_APP_API_URL}/files/${modelId}/${file}`, {
      headers: {
        'x-access-token': localStorage.getItem('token')
      }
    });

    const blob = await data.blob();

    // Creating new object of file
    const fileURL = window.URL.createObjectURL(blob);

    // Setting various property values
    let alink = document.createElement("a");
    alink.href = fileURL;
    alink.download = file;
    alink.click();

    return;
  }

  async delete(modelId, file) {
    if (modelId === '0') {
      return {};
    }

    const data = await fetch(`${process.env.REACT_APP_API_URL}/files/${modelId}/${file}`, {
      method: 'DELETE',
      headers: {
        'x-access-token': localStorage.getItem('token')
      }
    });
    return;;
  }

  async upload(modelId, files) {
    if (modelId === '0') {
      return [];
    }

    const response = [];
    const existingFiles = (await this.get(modelId)).map(f => f.name);

    for (const file of files) {

      if (!existingFiles.includes(file.name) || window.confirm(`${file.name} already exists. Overwrite file?`) === true) {
        const formData = new FormData();
        formData.append('file', file);

        const data = await fetch(`${process.env.REACT_APP_API_URL}/files/${modelId}/upload`, {
          method: 'POST',
          headers: {
            'x-access-token': localStorage.getItem('token')
          },
          body: formData
        });

        var json = await data.json();
        if (json.error) {
          json = { ...json, name: file.name }
        }
        response.push(json);
      }
    }

    return response;
  }
}

module.exports = new File();