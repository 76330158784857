import React from 'react';
import User from '../../services/user';

const AuthGuard = ({ children }) => {

    const hasValidJWT = () => {
        if (localStorage.getItem('token')) {
            User.getById('me')
                .then(data => {
                    if (data.error) {
                        localStorage.setItem('token', '');
                        window.location.href = "/login";
                        return;
                    }
                })
                .catch(err => {
                    localStorage.setItem('token', '');
                    window.location.href = "/login";
                    return;
                });
        }

        return localStorage.getItem("token");
    }

    if (hasValidJWT()) {
        return (
            <>
                {children}
            </>
        );
    }
    
    window.location.href = "/login";
};

export default AuthGuard;