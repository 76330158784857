
import moment from 'moment';

export default class Utils {
    static defaultComparator = (valueA, valueB, nodeA, nodeB, isDescending) => {
        valueA = valueA || '';
        valueB = valueB || '';

        if (valueA === valueB) { return 0 };
        if (valueA === '' || valueA === null) { return -1 };
        if (valueB === '' || valueB === null) { return 1 };

        if (!isNaN(valueA) && !isNaN(valueB)) {
            valueA = parseFloat(valueA);
            valueB = parseFloat(valueB);
        }
        else if (moment(valueA, 'DD-MM-YYYY', true).isValid() || moment(valueB, 'DD-MM-YYYY', true).isValid()) {
            valueA = moment(valueA, 'DD-MM-YYYY', true).get('year') * 10000 + moment(valueA, 'DD-MM-YYYY', true).get('month') * 100 + moment(valueA, 'DD-MM-YYYY', true).get('date');
            valueB = moment(valueB, 'DD-MM-YYYY', true).get('year') * 10000 + moment(valueB, 'DD-MM-YYYY', true).get('month') * 100 + moment(valueB, 'DD-MM-YYYY', true).get('date');
        }
        else {
            valueA = valueA.toString().trim().toLowerCase();
            valueB = valueB.toString().trim().toLowerCase();
        }
        
        if (valueA === valueB) { return 0 };
        return (valueA > valueB) ? 1 : -1;
    }
}